import * as React from 'preact';
import * as ReactDOM from 'preact';
import { useState, useEffect } from 'preact/hooks';

import { CSSTransition } from 'react-transition-group'
import Player from '../player'

import memoize from "memoize-one"

import * as Analytics from '../../helpers/analytics'

import { hexToRgb } from '../../helpers/color-transform'

import styled, { keyframes } from 'styled-components'

import * as WebFont from 'webfontloader';

import { FreckleAppContext, useAppContext } from '../../context/state'

export default function EmbedWrapper(props) {
  return (
    <FreckleAppContext>
    <Embed {...props}/>
    </FreckleAppContext>
  )
}

export function Embed(props) {
  const context = useAppContext();

  const [visibleProducts, setVisibleProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [videoDims, setVideoDims] = useState({height: 0, width: 0});

  useEffect(()=>{
    const { styleData } = context.state;
    if (styleData) {
      const fonts = [...new Set([
        styleData.button.font,
        styleData.headerText.font,
        styleData.bodyText.font
      ])].filter(item => item !== null)

      WebFont.load({
        google: {
          families: fonts,
        },
      });
    }
  }, [context.state.styleData])

  useEffect(()=>{
    if(!!props.bootstrap) {
      Analytics.setPreviewMode(true)
      // listenForBootstrapPayload()
    } else {
      context.loadSite(props.siteid, false, true);
    }
  }, [])

  const receiveDimensions = (dims) => setVideoDims(dims)

  const getPlayer = () => {
    const { styleData, videoData, allProducts } = context.state
    if(!!!videoData) return <span/>

    const isPhone = window.innerWidth < window.innerHeight*0.75
    const videoIsLandscape = videoDims.width > videoDims.height
    
    const autoHeight = (videoIsLandscape && !isPhone) || (isPhone)
    const autoWidth = !videoIsLandscape && !isPhone
    const dataString = videoData ? JSON.stringify(videoData) : null

    let autoplay = false
    let loop = true
    let pauseToShopEnabled = false
    if(!!styleData.playback) {
      autoplay = styleData.playback.autoplay
      loop = styleData.playback.loop
      pauseToShopEnabled = !!styleData.playback.pauseToShopEnabled
    }

    const videoId = videoData.videoId

    return(
      <Player
      embed
      shouldPresentProducts={(products)=>{
        var event = new CustomEvent("freckle-products-list-selected", 
          { 
            detail: {
              products: products,
              siteid: props.siteid,
              videoid: context.state.videoData.videoId,
            },
          });
        document.dispatchEvent(event);
      }}
      pauseToShopEnabled={pauseToShopEnabled}
      videoid={videoId} local="true" data={videoData} styleData={styleData} autoplay={autoplay} muted={autoplay} loop={true} hidesfreckles="false" 
      autoHeight={false}
      autoWidth={false}
      onSelectedFreckle={(productId)=> {
        // add view product analytics event
        Analytics.logEvent("view_product", productId, null, { source: "freckle" })

        const product = allProducts.find(prod=> prod.id === productId)
        Analytics.sendfb_ViewContent(product)

        var event = new CustomEvent("freckle-product-selected", 
          { 
            detail: {
              product: product,
              siteid: props.siteid,
              videoid: context.state.videoData.videoId,
            },
          });
        document.dispatchEvent(event);
      }}/>
    )
  }

  return (
    <React.Fragment>
    { getPlayer() }
    </React.Fragment>
  )

}
