import { h, Component } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import * as React from 'preact';
import * as ReactDOM from 'preact';
import styled from 'styled-components';

export const PickerStyle = Object.freeze({
  dropdown: 'dropdown',
  boxes: 'boxes',
  roundedDropdown: 'dropdown-rounded',
})

export default function PickerMenu(props) {
  let picker = null

  try {
    switch(props.styleData.productView.variantPickerStyle) {
      case PickerStyle.boxes:
        picker = <BoxesPickerMenu {...props}/>
        break
      case PickerStyle.dropdown:
      case PickerStyle.roundedDropdown:
      default:
        picker = <DefaultPickerMenu {...props}/>
        break
    }
  } catch(e) {
    picker = <BoxesPickerMenu {...props}/>
  }

  return picker
}

const Label = styled.div`
font-family: ${props=> props.styleData.bodyText.font };
font-size: 14px;
width: 100%;
color: #3f3f3f;
`

const BoxesPickerMenuContainer = styled.div`
padding: 10px 0;
display: flex;
flex-wrap: wrap;
gap: 5px;`

const BoxPickerItem = styled.button`
outline: none;
border: none;
height: 50px;
min-width: 55px;
padding: 10px 15px;
border: 1px solid ${(props)=>{
  if (props.styleData.name == "valence") return props.styleData.playback.scrubBarColor;
  return props.isAvailable ? 'black':'rgba(0,0,0,0.1)'
}};
transition: 0.2s;
font-size: 14px;
background-color: ${props=>{
  if (!props.isAvailable) {
    return 'rgba(255,255,255,0.05)';
  } else if (props.isSelected) {
    if (props.styleData.name == "valence") return props.styleData.playback.scrubBarColor;
    return 'black'
  } else {
    return 'transparent'
  }
}};
color: ${props=>{
  if (!props.isAvailable) {
    if (props.styleData.name == "valence") return props.styleData.playback.scrubBarColor;
    return 'white';
  } else if (props.isSelected) {
    return 'white'
  } else {
    if (props.styleData.name == "valence") return props.styleData.playback.scrubBarColor;
    return 'black'
  }
}};
opacity: ${props=>{
  return props.isAvailable ? 1:0.35;
}};
font-family: ${props=> props.styleData.bodyText.font };
cursor: ${props=> props.isAvailable ? 'pointer':'not-allowed'};
text-decoration: ${props=> props.isAvailable ? 'none':'line-through'};
`

function BoxesPickerMenu(props) {
  const { options, availOptions } = props

  const getItems = () => {
    return options.map(value => {
      const isAvailable = availOptions.includes(value)
      const isSelected = props.value === value
      return <BoxPickerItem 
      isSelected={isSelected}
      isAvailable={isAvailable}
      disabled={!isAvailable}
      {...props} 
      onClick={()=>{
          this.setState({open: false}, ()=>{
            this.props.onChange(value)
          })
        }}>
        {value}
        </BoxPickerItem>
    })
  }

  return (
    <BoxesPickerMenuContainer>
    {props.title && <Label styleData={props.styleData}>{ props.title }</Label>}
    { getItems() }
    </BoxesPickerMenuContainer>
  )
}




const DropdownMenu = styled.div`
position: relative;
font-size: 0.9em;
user-select: none;
flex:1;
margin: 0 0 25px 0;
border:1px solid transparent;
font-weight: 700;

label {
  color: black;
}

.picker-row {
  display: flex;
  flex-direction: row;
}

.picker-select-box {
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 5px 15px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  border-bottom: 2px solid #eeeeee;
  background-color: #eeeeee;
  margin-top: 8px;
  transition: 0.5s;

  ${props=> {
    switch (props.styleData.name) {
      case "minima":
        return `
background-color: transparent;
border: 1px solid black;`
    }
  }};

  ${props=>{
    if (props.styleData.productView.variantPickerStyle == PickerStyle.roundedDropdown) {
      return `border-radius: 6px;`
    }
  }}

  .title {
    float: right;
    font-size: 14px;
    opacity: 0.5;
  }

  .left {
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    flex-grow: 1;
  }

  .arrow {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid #8f8f8f;
      ${props=> {
    if (props.styleData.name == "minima") {
      return `
border-bottom: 5px solid #000;`
    }
  }};
    transition: 0.2s;
    transform: rotate(180deg);
  }

  .arrow.down {
    transform: rotate(180deg);
  }

  .arrow.up {
    transform: rotate(0deg);
  }
}

.picker-select-box.selected {
  ${props=>{
    if (props.styleData.productView.variantPickerStyle == PickerStyle.roundedDropdown) {
        return `
border-radius: 6px 6px 0 0;`
    }
  }}
}

.select-box.selected {

}

.picker-options-container  {
  position: relative;
  z-index: 100;
  background-color: #f5f5f5;
  overflow: scroll;
  overflow-x: hidden;
  color: black;
  transition: 0.2s;

  ${props=> {
    switch (props.styleData.name) {
      case "minima":
        return `
background-color: transparent;
border: 1px solid black;
border-top: 0;`
    }
  }};

    ${props=>{
    if (props.styleData.productView.variantPickerStyle == PickerStyle.roundedDropdown) {
        return `
border-radius: 0 0 6px 6px;`
    }
  }}


  .item {
    min-height: 30px;
    width: 100%;
    transition: 0.2s;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    user-select: none;
    cursor: pointer;
    border-top: 1px solid #fefefe;
  }
  .item:hover {
    background-color: #f7f7f7;
  }
  .item:active {
    background-color: #efefef;
  }

  .item.nostock {
    opacity: 0.2;
    pointer-events: none;
    text-decoration: line-through;
  }
}`

export class DefaultPickerMenu extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false
    }
  }

  getOptions = () => {
    const { options } = this.props
    const { availOptions } = this.props
    return options.map(value => {
      const itemClass = availOptions.includes(value) ? "item" : "item nostock"
      return <div className={itemClass} onClick={()=>{
          this.setState({open: false}, ()=>{
            this.props.onChange(value)
          })
        }}>
        {value}
        </div>
    })
  }

  render(){
    return(
      <DropdownMenu styleData={this.props.styleData}>
        {this.props.title && <Label styleData={this.props.styleData}>{ this.props.title }</Label>}
        <div className={"picker-select-box" + (this.state.open ? " selected":"")} onClick={()=>{
          this.setState({open: !this.state.open})
        }}>
        { this.props.value }
        <div className={`arrow ${this.state.open?'up':'down'}`}/>
        </div>
        <div className="picker-options-container" style={{maxHeight: this.state.open ? '200px' : '0'}}>
        { this.getOptions() }
        </div>
      </DropdownMenu>      
    )
  }

}
