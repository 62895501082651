import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.button`
position: absolute;
right: 20px;
bottom: 20px;
background-color: ${props=> props.styleData.playback.viewProductsButton ? props.styleData.playback.viewProductsButton.backgroundColor : 'white'};
font-family: ${props=> props.styleData.bodyText.font};
font-size: 14px;
font-weight: 700;
opacity: ${props=> !!props.visible ? '1':'0'};
transform: ${props=> !!props.visible ? 'scale(1)':'scale(0.95)'};
pointer-events: ${props=> !!props.visible ? 'auto':'none'};
transition: 0.15s;
color: ${props=> props.styleData.playback.viewProductsButton ? props.styleData.playback.viewProductsButton.textColor : '#1f1f1f'};
padding: 0;
outline: none;
border: none;
cursor: pointer;
overflow: hidden;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
height: 35px;
border-radius: ${props=>{
	try{
		switch(props.styleData.playback.viewProductsButton.style) {
			case "rounded":
				return "4px"
			case "pill":
				return "100px"
			case "square":
			default:
				return '0px'
		}
	} catch(e) {
		return '100px'
	}
}};

white-space: no-wrap;

transition: 0.5s;
max-width: ${props=> props.collapsed ? '35px':'200px'};
min-width: 35px;

img {
	height: ${props=>{ try { return props.styleData.playback.viewProductsButton.imageSize + "px" } catch(e) { return '17px' }}};
	padding-left: 10px;
	transition: 0.5s;
	padding-right: ${props=> props.collapsed ? '10px':'0px'};
}

${props=>{
	if (props.styleData.name == "minima") {
		return `border: 1px solid #4f4f4f;`
	}
}}
`

const Content = styled.div`
padding: ${props=> props.collapsed ? '0px':'0 12px'};
opacity: ${props=> props.collapsed ? '0':'1'};
max-width: ${props=> props.collapsed ? '0px':'200px'};
transition: 0.5s;
white-space: nowrap;

${props=>{
	if (props.styleData.name == "minima") {
		return `text-transform: uppercase;
font-size: 13px;
font-weight: bold;`
	}
}}`

const Count = styled.div`
background-color: rgba(0,0,0,0.9);
color: white;
height: 100%;
min-width: 35px;
font-size: 12px;
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
`

export default function ViewProductsButton(props){

	let cartImage
	try {
		switch(props.styleData.playback.viewProductsButton.image) {
			case "plus":
				cartImage = "/plus.svg"
				break
			case "solid-cart":
				cartImage = "/solid-cart.svg"
				break
			case "solid-bag-2":
				cartImage = "/solid-bag-2.svg";
				break
			case "bag7":
				cartImage = "/bag7.svg";
				break
			default:
				cartImage = "/solid-cart.svg"
				break
		}
	} catch(e) {
		cartImage = "/solid-cart.svg"
	}

	return(
		<Container {...props}>
		{cartImage && <img src={cartImage}/>}
		<Content {...props}>
		{ props.children }
		</Content>
		{props.showsCount && <Count>
		{ props.count }
		</Count>}
		</Container>
	)
}