import { h, Component } from 'preact';
import * as React from 'preact';
import * as ReactDOM from 'preact';

import { whitefreckle, bluefreckle } from './button-vectors'

import {coloredHotspotIcon, HotspotType} from './hotspot-types'

import styled, { keyframes } from 'styled-components'

import { hexToRgb } from '../../helpers/color-transform'
import { FreckleAppContext, useAppContext } from '../../context/state'

const ProductImage = styled.img`
max-height: 85%;
max-width: 85%;
object-fit: cover;
image-rendering: -webkit-optimize-contrast;
border-radius: ${props=>{
	switch(props.type) {
		case 'circleProductImage':
			return '10000px'
		case 'roundedProductImage': 
			return '10px'
		default:
			return '10px'
	}
}};
`

const ProductImageIconContainer = styled.div`
background-color: ${props=> props.color};
height: 100%;
width: 100%;

display: flex;
justify-content: center;
align-items: center;

transform: ${props=> `translateZ(0) scale(${props.scale || 1.0})`};

border-radius: ${props=>{
	switch(props.type) {
		case 'circleProductImage':
			return '10000px'
		case 'roundedProductImage': 
			return '12px'
		default:
			return '12px'
	}
}};
`

const FreckleItem = styled.div`
  -webkit-animation-timing-function: linear;
  animation-play-state: paused;
  display: inline-block;
  position: absolute;
  user-select: none;
  width: 70px;
  height: 70px;
  margin-left: -35px;
  margin-top: -35px;
  display:flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  pointer-events: all;

  &:hover {
  	cursor: pointer;
  }
`

const FrecklePulseAnimation = (color, scale) => keyframes`
0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(${color.r}, ${color.g}, ${color.b}, 1);
}

70% {
  transform: scale(1);
  box-shadow: 0 0 0 ${scale * 20}px rgba(${color.r}, ${color.g}, ${color.b}, 0);
}

100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 ${scale * 20}px rgba(${color.r}, ${color.g}, ${color.b}, 0);
}
`

const FreckleInnerItem = styled.div`
pointer-events: auto;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: center;
transition: 0.25s;
background-color: transparent;

border-radius: 1000px;

max-width: 50px;
max-height: 50px;

${props=>{
	if (!props.styleData) return ``
	const color = hexToRgb(props.styleData.playback.hotspotPulseColor)
	return `box-shadow: 0 0 0 0px rgba(${color.r}, ${color.g}, ${color.b}, 1);`
}}

animation: ${props=> FrecklePulseAnimation(hexToRgb(props.styleData.playback.hotspotPulseColor), props.scale)} 2s infinite;

img {
	height: 100%;
	width: 100%;
}
`

function ProductImageIcon(props){
	return(
		<ProductImageIconContainer {...props}>
			<ProductImage {...props} src={props.src}/>
		</ProductImageIconContainer>
	)
}

export default function Freckle(props) {
	const context = useAppContext()

	const selected = (freckleId) => {
		props.selected(freckleId, this.props.trackRef)
	}

	const hoverStart = () => {
		props.selected(props.freckleId, props.trackRef)
	}

	let freckleItem = null
	let { product } = this.props
	
	let scale = 1.0
	switch (product.properties.size) {
		case 'small':
			scale = 0.75
			break
		case 'medium':
			scale = 1.0
			break
		case 'large':
			scale = 1.75
			break
		default:
			scale = 1.0
			break
	}

	const showsProductImage = ['roundedProductImage', 'circleProductImage'].includes(product.properties.hotspotType)

	if(!showsProductImage) {
		freckleItem = coloredHotspotIcon(props.product.properties.hotspotType, props.product.properties.color, props.product.properties.accentColor)
	}

	return(
		<FreckleItem styleData={context.state.styleData} ref={props.trackRef}  onClick={()=>{selected(props.freckleId)}}
		style={{"animationName": "anim-" + props.freckleId + "-freckle", 
				"animationDuration": props.durationSeconds + "s"}}>
				<FreckleInnerItem 
				scale={scale}
				styleData={context.state.styleData} 
				style={{
					"opacity": props.visible ? 1:0,
					"transform": props.visible ? 'scale(1)':'scale(0)',
					"width": props.size + "px",
					"height": props.size + "px"
				}}>
				{ !showsProductImage && <img src={`data:image/svg+xml;base64,${btoa(freckleItem)}`}/> }
				{ showsProductImage && <ProductImageIcon scale={scale} color={product.properties.color} type={product.properties.hotspotType} src={product.imageUrl}/> }
				</FreckleInnerItem>
		</FreckleItem>
	)
	
}