import * as React from 'preact';
import * as ReactDOM from 'preact';

import CookieBanner from './cookie-banner'

import { useState, useEffect } from 'preact/hooks';

import CartManager from '../../helpers/cart-manager.js'

import { CSSTransition } from 'react-transition-group'

import { CartIcon, coloredCartIcon } from '../../helpers/style-enums'

import Product from '../product'
import Player from '../player'
import Cart from '../cart'
import style from './style.scss';

import { ButtonStyle } from '../../helpers/style-enums'

import { currency_symbols } from '../../helpers/currency-symbols'

import memoize from "memoize-one"

import * as Analytics from '../../helpers/analytics'

import { hexToRgb } from '../../helpers/color-transform'

import styled, { keyframes } from 'styled-components'

import ProductsListModal from '../microsite/ProductsListModal'

import * as WebFont from 'webfontloader';

import { FreckleAppContext, useAppContext } from '../../context/state'

const MicrositeRoot = styled.div`
background-color: ${props=> props.styleData.backgroundColor || "white"};

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  z-index: 0;

  .footer-carousel::-webkit-scrollbar {
    background-color: transparent;
    display: none;
    height: 0;
    width: 0;
  }

  a {
    text-decoration: none;
    outline: 0;
    border: 0;
    color: black;
    height: 45px;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.25s, background-color 0.25s, border 0.25s;
    font-size: 0.9em;
    font-weight: bold;
  }
}`

const InterativeNoticeContainer = styled.div`
margin: 0 auto;
margin-top: 35px;
width: calc(100vw - 20px);
max-width: 450px;
background-color: #f0f0f0;
padding: 0px;
display: flex;
flex-direction: column;
align-items: center;
transition: max-height 250ms, transform 250ms, opacity 250ms;
max-height: ${({ transitionState }) => (transitionState === "entered" ? '1000px' : '0')};
transform: ${({ transitionState }) => (transitionState === "entered" ? 'scale(1)' : 'scale(0.95)')};
opacity: ${({ transitionState }) => (transitionState === "entered" ? '1' : '0')};
border-radius: 4px;
overflow: hidden;
box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
`
const InterativeNoticeContent = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 20px;
`
const breatheAnimation = keyframes`
 0% { transform: translateY(0px) translateZ(-420px); }
 50% { transform: translateY(-10px) translateZ(-420px); }
 100% { transform: translateY(0px) translateZ(-420px); }
`
const InteractiveIcon = styled.img`
height: 35px;
width: 35px;
margin-right: 20px;
animation-name: ${breatheAnimation};
animation-duration: 1s;
animation-iteration-count: infinite;
opacity: 0.75;
transform: translateZ(-420px);
`

const InteractiveNoticeText = styled.div`
display: flex;
flex-direction: column;`

const InteractiveHeader = styled.h2`
padding: 0;
margin: 0;
color: black;
font-size: 16px;
font-weight: bold;
font-family: ${props=> props.styleData.headerText.font};
`
const InteractiveBody = styled.p`
padding: 0;
margin: 0;
margin-top: 5px;
font-family: ${props=> props.styleData.bodyText.font};
`
const InteractiveNoticeExitButton = styled.button`
width: 100%;
outline: 0;
border: 0;

font-family: ${({styleData})=> styleData.button.font} !important;
background-color: ${({styleData})=> styleData.button.color} !important;
color: ${({styleData})=> styleData.button.textColor} !important;

text-decoration: none;
outline: 0;
border: 0;
height: 45px;
cursor: pointer;
transition: background-color 0.2s, color 0.2s, border 0.2s;
font-size: 14px;
line-height: 14px;
font-weight: bold;

letter-spacing: 1px;

display: flex;
justify-content: center;
align-items: center;

text-transform: uppercase;

${({ styleData }) =>{
  if(!!styleData){
    let buttonRadius = '0px'
    switch(styleData.button.style){
      case ButtonStyle.square:
      buttonRadius = '0px'
      break
      case ButtonStyle.rounded:
      buttonRadius = '6px'
      break
      case ButtonStyle.pill:
      buttonRadius = '1000px';
      break
    }
    return `
    font-family: ${styleData.button.font} !important;
    background-color: ${styleData.button.color} !important;
    color: ${styleData.button.textColor} !important;
    `
  }
}}
`

function InterativeNotice(props){
  return(
    <InterativeNoticeContainer {...props}>
    <InterativeNoticeContent>
      <InteractiveIcon src="/tap2.svg"/>
      <InteractiveNoticeText>
      <InteractiveHeader styleData={props.styleData} {...props}>SHOPPABLE VIDEO</InteractiveHeader>
      <InteractiveBody styleData={props.styleData}>Tap on hotspots to view and purchase products.</InteractiveBody>
      </InteractiveNoticeText>
    </InterativeNoticeContent>
    <InteractiveNoticeExitButton styleData={props.styleData} onClick={props.onOkay} styleData={props.styleData}>Okay</InteractiveNoticeExitButton>
    </InterativeNoticeContainer>
  )
}


const SiteFooter = styled.div`
width: 100%;
height: 40px;
text-align: center;
margin-top: 25px;
opacity: 0.5;
font-size: 14px;
display: flex;
flex-direction: column;
line-height: 30px;
padding-bottom: 200px;
font-family: ${props=> props.styleData.bodyText.font};

a {
  text-decoration: none;
}

hr {
  width: 20px;
  height: 0;
  border: 0.5px solid #ececec;
}
`

const Header = styled.div`
top: 0;
width: calc(100% - 20px);
padding: 0 10px;
height: ${props=>{
  const { headerHeight } = props.styleData
  if (!headerHeight) return '50px';
  switch(headerHeight) {
    case 'small':
      return '35px';
    case 'medium':
      return '50px';
    case 'large':
      return '75px';
    default:
      return '50px'
  }
}} !important;
display: flex;
justify-content: space-between;
align-items: center;
position: fixed;
z-index: 50;
background-color: ${props=> props.styleData.header.color || "white"} !important;

${props=>{
  if (props.styleData.header.shadow) {
    return `box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25)`
  } else {
    return `border-bottom: 1px solid rgba(0,0,0,0.05)`
  }
}};

.cart-count {
  background-color: ${props=> props.styleData.colors.accentColor} !important;
}

.header-img {
  padding: 0;
  margin: 0;
  height: ${props=> props.styleData.header.logo.size}px !important;
  max-height: 75%;
}

.header-img-link {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.shim {
  height: 100%;
  width: 60px;
  margin: 0 10px;
}

.cart-button {
  height: 100%;
  width: 50px;  
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  .cart-img {
    max-width: 50px;
    max-height: 60%;
    height: ${props=> props.styleData.header.cart.size + 'px'};
    width: auto;
  }

  .cart-count {
    position: absolute;
    right: 0px;
    bottom: 5px;
    background-color: red;
    
    border-radius: ${props=>{
      try {
        return props.styleData.header.cart.badgeBorderRadius + "px"
      } catch {
        return '1000px'
      }
    }};

    min-height: 17px;
    min-width: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: bold;
    background-color: ${props=> props.styleData.colors.accentColor} !important;
  }
}

img {
  height: 90%;
  max-width: 400px;
  object-fit: contain; 
}`

const FooterItem = styled.div`
margin-bottom: 10px;
z-index: 0;
cursor: pointer;
width: ${props=>{
  switch(props.styleData.carousel.itemSize) {
    case 'small':
      return `calc(33% - 20px) !important`
    case 'large':
      return `calc(100% - 20px) !important`
    case 'medium':
    default:
      return `calc(50% - 20px) !important`
  }
}};
padding-bottom: 10px;
letter-spacing: 1.0px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

.separator {
  width: 15px;
  height: auto;
  margin-top: 10px;
}

.img-container {
  width: ${props=>{
    switch(props.styleData.carousel.itemSize) {
      case 'small':
        return `calc(100% - 20px)`
      case 'large':
        return `100%`
      case 'medium':
      default:
        return `calc(100% - 10px)`
    }
  }};

  padding-top: 100%;
  position: relative;
  overflow: hidden;

  img {
    border-radius: ${props=> (props.styleData.carousel.imageBorderRadius + 'px') || 0};
    ${props=>{
      if (props.styleData.carousel.imageBackgroundColor) {
        return `background-color: ${props.styleData.carousel.imageBackgroundColor};`;
      }
    }};
    ${props=>{
      if (props.styleData.carousel.imageShadow) return `box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.025);`
    }}
    object-fit: contain;
    height: 100%;
    width: 100%;

    position: absolute;
    left: 0;
    top: 0;
  }

}

h3 {
  padding: 0;
  width: calc(100% - 20px);
  margin: 0 auto;
  margin-top: 10px;
  
  line-height: 20px;
  font-size: ${props=> props.styleData.carousel.headerText ? (props.styleData.carousel.headerText.size + "px"): "14px"};
  line-height: ${props=> props.styleData.carousel.headerText ? ((props.styleData.carousel.headerText.size * 1.5) + "px"): "14px"};
  font-family: ${props=> props.styleData.headerText.font} !important;

    ${props=>{
    try {
      const headerText = props.styleData.carousel.headerText
      if (headerText.size) {
        return `
font-size: ${headerText.size}px;
font-weight: ${headerText.weight};
line-height: ${headerText.lineHeight || (headerText.size*1.5)}px;`
      }
      throw null
    } catch(e) {
      return `
font-weight: normal;
font-size: 14px; 
line-height: calc(14px * 1.5);`
    }
  }}
  color: ${props=> props.styleData.carousel.headerText ? props.styleData.carousel.headerText.color : "#000000"};
  text-align: ${props=> props.align || 'center'};
}
h4 {
  text-align: ${props=> props.align || 'center'};
  
    ${props=>{
    try {
      const subheaderText = props.styleData.carousel.subheaderText
      if (subheaderText.size) {
        return `
font-size: ${subheaderText.size}px;
font-weight: ${subheaderText.weight};
line-height: ${subheaderText.lineHeight || (subheaderText.size*1.5)}px;`
      }
      throw null
    } catch(e) {
      return `
font-weight: normal;
font-size: 14px; 
line-height: calc(14px * 1.5);`
    }
  }}

  color: ${props=> props.styleData.carousel.subheaderText ?  props.styleData.carousel.subheaderText.color : "#8e8e8e"};


  padding: 0;
  margin: 0;
  margin-top: 10px;
  font-family: ${props=> props.styleData.bodyText.font} !important;

  align-self: center;

  ${props=>{
    if (props.styleData.name == "minima") {
      return `
background-color: black;
color: white;
padding: 0 10px;`
    } else if (props.styleData.name == "valence") {
      return `
background-color: ${props.styleData.playback.scrubBarColor};
color: white;
padding: 2.5 5px;`
    }
  }}
}


@media (min-width: 1200px) {
    width: ${props=>{
      switch(props.styleData.carousel.itemSize) {
        case 'small':
          return `calc(16% - 20px) !important`
        case 'large':
          return `calc(30% - 20px) !important`
        case 'medium':
        default:
          return `calc(20% - 20px) !important`
      }
    }};

    img {
        max-height: 60vh;
    }
}`

const PlayerContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
padding: 0;
margin: 0;
background-color: black;
margin-bottom: 25px;
margin-top: ${props=>{
  const { headerHeight } = props.styleData
  if (!headerHeight) return '50px';
  switch(headerHeight) {
    case 'small':
      return '35px';
    case 'medium':
      return '50px';
    case 'large':
      return '75px';
    default:
      return '50px'
  }
}} !important;

// @media (min-aspect-ratio: 1/1) {
//   height: 65vh;
//   min-height: 300px;
// }
`

const FooterCarousel = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: flex-start;
justify-content: center;
width: 100%;
padding: 15px 10px;
overflow: scroll;
gap: ${props=>{
  switch(props.styleData.carousel.itemSize) {
    case 'small':
      return `10px`
    case 'large':
      return `20px`
    case 'medium':
    default:
      return `10px`
  }
}};

@media (min-aspect-ratio: 1/1) {
  justify-content: center;
  align-items: flex-start;
  max-width: 900px;
  gap: 10px !important;
}`

export default function MicrositeWrapper(props) {
  return (
    <FreckleAppContext>
    <Microsite {...props}/>
    </FreckleAppContext>
  )
}

export function Microsite(props){
  const context = useAppContext();

  const [, forceUpdate] = useState();

  const [visibleProducts, setVisibleProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showsCart, setShowsCart] = useState(false);
  const [showsInteractivityNotice, setShowsInteractivityNotice] = useState(false);
  const [videoDims, setVideoDims] = useState({height: 0, width: 0});

  const { videoData, styleData, allProducts } = context.state;
  const videoId = videoData ? videoData.videoId : null

  useEffect(()=>{
    const { styleData } = context.state;
    if (styleData) {
      const fonts = [...new Set([
        styleData.button.font,
        styleData.headerText.font,
        styleData.bodyText.font
      ])].filter(item => item !== null)

      WebFont.load({
        google: {
          families: fonts,
        },
      });
    }
  }, [context.state.styleData])

  useEffect(()=>{
    if(!!props.bootstrap) {
      Analytics.setPreviewMode(true)
      context.setEmbedMode(true)
    } else {
      context.loadSite(props.siteid);
    }

    CartManager.setStoreId(props.siteid || "test")
  }, [])

  const getProductModal = (selectedProduct) => {
    const { videoData, allProducts } = context.state;
    if (!videoData || !allProducts ) return <span/>

    let freckleId = null
    if (selectedProduct) {
      freckleId = selectedProduct.id
    }

    let product = null
    if(freckleId) product = allProducts.find((product)=> product.id === freckleId )
    return(
      <Product
      visible={product !== null}
      storeType={videoData.storeType || "shopify"}
      storeUrl={videoData.storeUrl || videoData.shopifyStore}
      currencySymbol={'$'}
      product={product} 
      onAddProduct={product=>{
        setShowsCart(true);
      }}
      onClose={(cancelPlay)=>{
        setSelectedProduct(null)
        if (!cancelPlay) {
          setTimeout(()=>{
            document.dispatchEvent(new CustomEvent("shouldPlayVideo", {}))
          }, 300)
        }
      }}/>
    ) 
  }

  const getCart = () => {
    if(!videoData) return <span/>
    return(
      <CSSTransition in={showsCart} timeout={250} classNames="show-cart" unmountOnExit>
      <Cart 
      storeType={videoData.storeType || "shopify"}
      currencySymbol={'$'}
      data={videoData} 
      onClose={()=>{
        setShowsCart(false)
        setTimeout(()=>{
          document.dispatchEvent(new CustomEvent("shouldPlayVideo", {}))
        }, 300)
      }}/>
      </CSSTransition>
    )
  }

  const receiveDimensions = (dims) => setVideoDims(dims)

  const getPlayer = () => {
    const { styleData, videoData, allProducts } = context.state
    if(!!!videoData) return <span/>

    const dataString = videoData ? JSON.stringify(videoData) : null

    let autoplay = false
    let loop = true
    let pauseToShopEnabled = false
    if(!!styleData.playback) {
      autoplay = styleData.playback.autoplay
      loop = styleData.playback.loop
      pauseToShopEnabled = !!styleData.playback.pauseToShopEnabled
    }

    const videoId = videoData.videoId

    return(
      <Player
      shouldPresentProducts={(products)=>{
        setVisibleProducts(products)
        document.dispatchEvent(new CustomEvent("shouldPauseVideo", {}))
      }}
      pauseToShopEnabled={pauseToShopEnabled}
      videoid={videoId} local="true" data={videoData} styleData={styleData} autoplay={autoplay} muted={autoplay} loop={true} hidesfreckles="false" 
      onSelectedFreckle={(productId)=> {
        // add view product analytics event
        Analytics.logEvent("view_product", productId, null, { source: "freckle" })

        const product = allProducts.find(prod=> prod.id === productId)
        setSelectedProduct(product);
        Analytics.sendfb_ViewContent(product)
      }}/>
    )
  }

  const headerImage = () => {
    const { styleData } = context.state
    if(styleData) { 
      const logoData = styleData.header.logo.data
      const logoHref = styleData.header.logo.href
      if(logoData && !!logoHref) return <a class="header-img-link" href={logoHref}><img class="header-img" src={logoData}/></a>
      if(logoData) return <img class="header-img" src={logoData}/>
      return <span/>
    }
  }

  const cartImage = () => {
    const { videoData, styleData } = context.state
    const count = CartManager.count()
    if(!!videoData && !!styleData) { 
      const cartIconName = styleData.header.cart.icon
      let logoData = ''

      let cartIconColor = "#000"
      if(!!styleData.header.cart.color) cartIconColor = styleData.header.cart.color
      
      let cartIconSize = 60
      if(!!styleData.header.cart.size) cartIconSize = styleData.header.cart.size

      if(!!!logoData) logoData = `data:image/svg+xml;base64,${btoa(coloredCartIcon(cartIconName, cartIconColor))}`
      if(logoData) return(
        <span>
        <img class="cart-img" style={{
          height: `${cartIconSize}px`
        }} src={logoData}/>
        <div class="cart-count" style={{
          display: !!count ? 'flex' : 'none'
        }}>
          { count }
        </div>
        </span>
      )
      return <span/>
    }
  }

  const addhttp = (url) => {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "http://" + url;
    }
    return url;
  }

  const isCookieBannerEnabled = () => {
    if(styleData && styleData.analytics){
      return styleData.analytics.showCookieConsent
    }
    return false
  }

  const productFooter = () => {
    if(!!videoData) {
      return (
        <FooterCarousel styleData={styleData}>
        { 
          allProducts.map(product=>{
            return (
              <FooterItem
              size={"medium"}
              styleData={styleData}
              onClick={()=>{
                document.dispatchEvent(new CustomEvent("shouldPauseVideo", {}))
                setSelectedProduct(product);

                // add view product analytics event
                Analytics.logEvent("view_product", product.id, null, { source: "grid" })
                Analytics.sendfb_ViewContent(product)
              }}>
              <div class="img-container">
              <img src={product.fullSizeImageUrl || product.imageUrl}/>
              </div>

              <h3>{product.title}</h3>
              { (styleData.name == "sparrow") && <img class="separator" src="/zigzag.svg"/> }
              <h4>${product.price}</h4>
              </FooterItem>
            )
          }) 
        }
        </FooterCarousel>
      )      
    }
  }

  if (!videoData || !styleData) return <span/>

  let copyright = ""
  let privacyUrl = null
  let termsUrl = null
  let cookiesUrl = null
  let showsCookieBanner = false;
  if(!!styleData && !!styleData.legal) {
    copyright = `${styleData.legal.copyright}`
    if(!!styleData.legal.privacyUrl && !!styleData.legal.privacyUrl.length) privacyUrl = addhttp(styleData.legal.privacyUrl)
    if(!!styleData.legal.termsUrl && !!styleData.legal.termsUrl.length) termsUrl = addhttp(styleData.legal.termsUrl)
    if(!!styleData.legal.cookiesUrl && !!styleData.legal.cookiesUrl.length) cookiesUrl = addhttp(styleData.legal.cookiesUrl)

    showsCookieBanner = isCookieBannerEnabled() && Analytics.isTrackingEnabled() && !Analytics.consented() && !Analytics.declined()
  }

  return (
    <MicrositeRoot styleData={styleData}>
        <Header styleData={styleData}>
          { (!!styleData && styleData.header.logo.position === "center") && <div class="shim"/> }
          { headerImage() }
          <button class="cart-button" onClick={()=>{ 
            setTimeout(()=>{
              document.dispatchEvent(new CustomEvent("shouldPauseVideo", {}))
            }, 300)
            setShowsCart(true)
          }}>
          { cartImage() }
          </button>
        </Header>
        <PlayerContainer styleData={styleData}>
          { 
            getPlayer() 
          }
        </PlayerContainer>

        <CSSTransition
            in={showsInteractivityNotice && !!styleData}
            timeout={250}
            unmountOnExit 
            mountOnEnter>
            {(transitionState) =>
          <InterativeNotice 
          styleData={styleData}
          onOkay={()=> setShowsInteractivityNotice(false)}
          transitionState={transitionState}
          styleData={styleData}/>
        }
        </CSSTransition>

        <div class="footer">
          { productFooter() }
        </div>

        <SiteFooter styleData={styleData}>
        <span>© 2021 {copyright}</span>
        <hr/>
        {(!!privacyUrl) && <a target="_blank" href={privacyUrl}>Privacy Policy</a>}
        {(!!termsUrl) && <a target="_blank" href={termsUrl}>Terms of Service</a>}
        {(!!cookiesUrl) && <a target="_blank" href={cookiesUrl}>Cookies Policy</a>}
        
        {isCookieBannerEnabled() && <a href="#" onClick={()=>{
          Analytics.resetConsent()
          forceUpdate(Math.random())
        }}>Update Tracking Preferences</a>}
        </SiteFooter>

        {showsCookieBanner && <CookieBanner 
        styleData={styleData} 
        onDecline={()=>{
          Analytics.decline()
          forceUpdate(Math.random())
        }}
        onAccept={()=>{
          Analytics.consentToTracking()
          forceUpdate(Math.random())
        }}/>}

        { getCart() }

        {videoData && <ProductsListModal
        onSelect={product=>{
          setVisibleProducts(null)
          setSelectedProduct(product)
        }}
        onClose={()=> {
          setVisibleProducts(null)
          setTimeout(()=>{
            document.dispatchEvent(new CustomEvent("shouldPlayVideo", {}))
          }, 300)
          
        }}
        visible={!!visibleProducts}
        products={visibleProducts}
        />}

        { getProductModal(selectedProduct) }


      </MicrositeRoot>
  )

}



