import { h, Component } from 'preact';
import { useState, useRef, useEffect } from 'preact/hooks';
import * as React from 'preact';
import * as ReactDOM from 'preact';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import { currency_symbols } from '../../helpers/currency-symbols.js'
import axios from 'axios'

import { exitProductView } from './button-vectors'

// import style from './style.scss';

import memoize from "memoize-one"
import * as Analytics from '../../helpers/analytics'
import PickerMenu from './picker-menu'
import CartManager from '../../helpers/cart-manager.js'

import styled from 'styled-components'

import MoonLoader from "react-spinners/MoonLoader";

import { print } from 'graphql';
import gql from 'graphql-tag';

import { CSSTransition } from 'react-transition-group'

import { useLockBodyScroll } from '../../helpers/useLockBodyScroll'

import Button from '../../components/Button'

import { FreckleAppContext, useAppContext } from '../../context/state'

const TOOLTIP_WIDTH = 300
const TOOLTIP_HEIGHT = 100

const Tooltip = styled.div`
pointer-events: all;
height: calc(100% - 0px);
width: 100vw;
max-width: 600px;
max-height: 1200px;

display: flex;
flex-direction: column;
align-items: center;

overflow: hidden;

pointer-events: all;

position: relative;
z-index:100;

box-shadow: none;

transform: scale(1);

.swiper-container-thumbs {
	padding: 10px 10px !important;
	padding-top: 10px !important;
}

.swiper-container {
	padding: 0;
	margin: 0;
	outline: none;
	
}

.swiper-wrapper {
	height: auto !important;
}

.swiper-slide {
	padding: 0;
	margin: 0;
	height: auto !important;
}`

const ProductImagesContainer = styled.div`
position: relative;

width: 100%;
background-color: ${props=> props.styleData.productView ? props.styleData.productView.backgroundColor : "white"};

.controls-container {
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: 1000;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
pointer-events: none;

button {
	margin: 10px;
	height: 50px;
	width: 50px;

	${props=>{
		try{
			switch(props.styleData.productView.photos.arrows) {
				case "minimalist":
				case "minimalist2":
					return `
background-color: transparent;`
					break
				case "basic":
				default:
					return `
background-color: rgba(255,255,255,0.5);`
					break
			}
		} catch(e) {
								return `
background-color: rgba(255,255,255,0.5);`
		}
	}};

	border: 0;
	outline: none;
	border-radius: 10000px;
	cursor: hand;
	transition: 0.2s;
	pointer-events: auto;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.left {
	img {
			${props=>{
		try{
			switch(props.styleData.productView.photos.arrows) {
				case "minimalist":
					return `
height: 25px;
width: 25px;`
					break
				case "basic":
				default:
					return `
height: 15px;
width: 15px;`
					break
			}
		} catch(e) {
								return `
height: 15px;
width: 15px;`
		}
	}};

		margin-right: 3px;
		opacity: 0.5;
	}
}

.right {
	img {
		${props=>{
		try{
			switch(props.styleData.productView.photos.arrows) {
				case "minimalist":
					return `
height: 25px;
width: 25px;`
					break
				case "basic":
				default:
					return `
height: 15px;
width: 15px;`
					break
			}
		} catch(e) {
								return `
height: 15px;
width: 15px;`
		}
	}};
		margin-left: 3px;
		opacity: 0.5;
	}
}

@media (min-aspect-ratio: 1/1) {
	button:hover {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
		background-color: rgba(255,255,255,1);

		img {
			opacity: 1;
		}
	}
}
}`

const Background = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background-color: ${({ transitionState }) => (transitionState === "entered" ? 'rgba(0,0,0,0.4)' : 'transparent')};
transition: 0.25s, width 0s, height 0s;
pointer-events: auto;
z-index: 1000;

@media (max-width: 1200px) {
	justify-content: flex-end;
}
`

const Container = styled.div`
height: 90vh;
width: 100vw;
margin-bottom: ${({ transitionState }) => (transitionState === "entered" ? '0' : '-90vh')};
transition: 0.25s, width 0s, height 0s;

@media (min-width: 1200px) {
	max-height: 800px;
	max-width: 600px;
	margin-bottom: 0;
	opacity: ${({ transitionState }) => (transitionState === "entered" ? '1' : '0')};
	transform: ${({ transitionState }) => (transitionState === "entered" ? 'scale(1)' : 'scale(0.95)')};
}

font-family: ${props=> props.styleData.bodyText.font} !important;

h1,h2,h3,h4 {
	font-family: ${props=> props.styleData.headerText.font} !important;
}
`

const PullHandleOversized = styled.div`
width: 100%;
height: 50px;
background-color: ${props=> props.styleData.productView ? props.styleData.productView.backgroundColor : "white"};
z-index: 2000;

${props=>{
	if (props.styleData.productView && props.styleData.productView.borderRadius) {
		return `border-radius: ${props.styleData.productView.borderRadius} ${props.styleData.productView.borderRadius} 0 0`;
	}
}};

&::after {
	pointer-events: none;
	content: '';
	width: 80px;
	
	${props=>{
		try {
			if (props.styleData.name == "minima") {
			return `
background-color: black;
border-radius: 0;
height: 1px;
top: 7px;`
			} else {
				return `
background-color: rgba(0,0,0,0.1);
border-radius: 100px;
height: 4px;
top: 5px;`
			}
		} catch(e) {
			return `
background-color: rgba(0,0,0,0.1);
border-radius: 100px;
height: 4px;
top: 5px;`
		}
	}}
	position: absolute;
	left: calc(50% - 40px);
}`

const PullHandleContainer = styled.div`
width: 100%;
height: 12.5px;
background-color: transparent;
position: fixed;
top: 0;
left: 0;
z-index: 2000;
overflow: hidden;
`

function PullHandle(props) {
	return (
		<PullHandleContainer>
		<PullHandleOversized {...props}/>
		</PullHandleContainer>
	)
}




const Scroll = styled.div`
overflow: scroll;
width: 100%;
height: 100%;
position: relative;
background-color: ${props=> props.styleData.productView ? props.styleData.productView.backgroundColor : "white"};

${props=>{
	if (props.styleData.productView && props.styleData.productView.borderRadius) {
		return `border-radius: ${props.styleData.productView.borderRadius} ${props.styleData.productView.borderRadius} 0 0`;
	}
}};

.detail-header {
	display: flex;
	flex-direction: ${props=> props.styleData.productView.header.direction || 'column'};
	gap: 15px;
	justify-content: center;
	align-items: ${props=>{ 
		try { 
			switch (props.styleData.productView.header.align) {
				case "left":
					return 'flex-start'
				case "center":
					return 'center'
			}
		} catch(e) { 
			return 'flex-start' 
		}
	}};

	.separator {
		width: 30px;
		height: auto;
		margin-top: 10px;
	}
}

.detail-header.bottom {
	padding-top: 2em;
}

h3.product-title {

	${props=>{
		try {
			const titleText = props.styleData.productView.header.titleText
			if (titleText.size) {
				return `
font-size: ${titleText.size}px;
font-weight: ${titleText.weight};
line-height: ${titleText.lineHeight || (titleText.size*1.65)}px;`
			}
			throw null
		} catch(e) {
			return `
font-weight: 700;
font-size: 30px;	
line-height: 45px;`
		}
	}}

	color: black;
	padding: 0;
	margin: 0;
	text-align: ${props=>{ try { return props.styleData.productView.header.align } catch(e) { return 'left' }}};
}

h4.product-price {
	font-weight: font-size: ${props=>{
		try {
			return props.styleData.productView.header.priceText.weight
		} catch(e) {
			return 'medium'
		}
	}};
	font-size: ${props=>{
		try {
			return props.styleData.productView.header.priceText.size + "px"
		} catch(e) {
			return '16px'
		}
	}};
	letter-spacing: 1px;
	color: ${props=>{
		try {
			const color = props.styleData.productView.header.priceText.color
			if (color) return `${color}`
			throw null
		} catch(e) {
			return `#3f3f3f`
		}
	}};
	padding: 0;
	margin: 0px 0 20px 0;
	text-align: ${props=>{ try { return props.styleData.productView.header.align } catch(e) { return 'left' }}};

	${props=>{
		if (props.styleData.name == "minima") {
			return `
margin-top: 20px;
border: 1px solid black;
opacity: 1;
color: white;
background-color: black;
padding: 0px 10px;
`
		} else if (props.styleData.name == "valence") {
			return`
margin-top: 20px;
opacity: 1;
color: white;
background-color: ${props.styleData.playback.scrubBarColor};
padding: 10px 10px;`
		}
	}};
}

.description {
	margin-top:15px;

	${props=>{
		try {
			const bodyText = props.styleData.productView.bodyText
			if (bodyText.size) {
				return `
font-size: ${bodyText.size}px;
font-weight: ${bodyText.weight};
line-height: ${bodyText.lineHeight || (bodyText.size*1.65)}px;`
			}
			throw null
		} catch(e) {
			return `
font-weight: normal;
font-size: 1em;	
line-height: 1.5em;`
		}
	}}
	
	color: black;
	letter-spacing: 1px;

	img { display: none; }
	button { display: none; }
	iframe { display: none };
	video { display: none };
}

.details {
	padding: 0 2em 2em 2em;
	padding-bottom: 100px;
	background-color: ${props=> props.styleData.productView ? props.styleData.productView.backgroundColor : "white"};
	min-height: 50vh;

	p {
		padding-bottom: 15px;
		padding-top: 10px;
		margin: 0;
	}
}

@media (min-aspect-ratio: 1/1) {
${props=>{
	if (props.styleData.productView && props.styleData.productView.borderRadius) {
		return `border-radius: ${props.styleData.productView.borderRadius}`;
	}
}};

.scroll {
	padding-top: 0px;
}
}`

const WOO_SINGLE_PRODUCT_QUERY = gql`
query getProduct($id: ID! ) {
  product(id: $id) {

          id
          slug
          name
          type
          databaseId
          shortDescription
          image {
              id
              sourceUrl
              altText
          }
          galleryImages {
              nodes {
                  id
                  sourceUrl
                  altText
              }
          }
          ... on SimpleProduct {
              onSale
              price
              content
              regularPrice
              stockStatus
          }
          ... on VariableProduct {
              onSale
              price
              content
              regularPrice
              variations(first: 50) {
	            nodes {
	              id
	              databaseId
	              name
	              price
	              sku
	              stockStatus
	            }
	          }
          }
        }
      
}
`;

const ProductImageElement = styled.img`
height: 600px;
max-height: 55vh;
width: 100%;
overflow: hidden;
object-fit: ${props=> !!props.fillArea ? 'cover' : 'contain'};
cursor: pointer;
user-select: none;
border-bottom: 1px solid #efefef;
background-color: ${props=> props.styleData.productView ? props.styleData.productView.backgroundColor : "white"};
`

function ProductImage(props){
	const [fillArea, setFillArea] = useState(false)
	return(
		<ProductImageElement 
		{...props}
		fillArea={fillArea}
		onLoad={(e)=>{
			if(e.target.naturalWidth > e.target.naturalHeight) {
				setFillArea(true)
			}
		}}/>
	)
}

export default function Product(props) {
	SwiperCore.use([Pagination]);

	const context = useAppContext();

	const swiperRef = useRef(null)
	const scrollRef = useRef(null)

	const [product, setProduct] = useState(null)
	const [variants, setVariants] = useState(null)
	const [selectedVariant, setSelectedVariant] = useState(null)

	const { styleData } = context.state;

	useEffect(()=>{
		if (props.product) fetchProduct()
	}, [props.product])

	useEffect(()=>{
		document.body.style.overflow = props.visible ? 'hidden':'auto'

		return ()=>{
			document.body.style.overflow = 'auto'
		}
	}, [props.visible]);

	const fetchProduct = async () => {
		switch(props.storeType){
			case "shopify":
				const resp = await axios.get(`${props.product.linkUrl}.js`)

				const product = {
					title: resp.data.title,
					images: resp.data.images.map(image=> image.replace(/\.jpg|\.png|\.gif|\.jpeg/g, (match) => '_grande'+match )),
					description: resp.data.description,
					options: resp.data.options.map(o=> o.name),
				}

				const shopifyVariants = resp.data.variants.map(variantData=>{
					const noneToNull = (item) => {
						if(!!item && item == 'None') return null
						return item
					}

					return {
						id: variantData.id,
						title: variantData.title,
						option1: noneToNull(variantData.option1),
						option2: noneToNull(variantData.option2),
						option3: noneToNull(variantData.option3),
						available: variantData.available,
						price: variantData.price / 100,
						options: [null, null, null],
					}
				})

				let firstAvailVariant = shopifyVariants.find(variant=> variant.available)
				if(!!!firstAvailVariant) firstAvailVariant = shopifyVariants[0]

				setProduct(product)
				setVariants(shopifyVariants)
				setSelectedVariant(firstAvailVariant)

				break

			case "woo":
			    const payload = {
			      query: print(WOO_SINGLE_PRODUCT_QUERY),
			      variables: {
			        id: props.product.gqlid,
			      }
			    }
			    const url = `https://${props.storeUrl}/graphql`
			    const result = await axios.post(url, payload)

			    try {
			    	const productData = result.data.data.product

			    	const product = {
						title: productData.name,
						images: productData.galleryImages.nodes.concat([productData.image]).filter(item=>!!item).map(item=>item.sourceUrl),
						description: productData.content
					}

			    	let variants
			    	if(productData.type === "VARIABLE") {
			    		variants = productData.variations.nodes.map(v=>{

			    			const comps = v.name.split(`${productData.name} - `)[1].split(', ')
			    			return {
			    				id: v.databaseId,
			    				title: v.name.replace(`${productData.name} - `, ''),
			    				option1: comps[0],
			    				option2: comps[1],
			    				option3: comps[2],
			    				available: v.stockStatus === "IN_STOCK",
			    				price: v.price ? parseFloat(v.price.replace(new RegExp('\\$', 'g'), '')) : 0
			    			}
			    		})
			    	} else {
			    		variants = [{
			    			id: productData.databaseId,
			    			title: null,
			    			option1: null,
			    			option2: null,
			    			option3: null,
			    			available: productData.stockStatus === "IN_STOCK",
			    			price: productData.price ? parseFloat(productData.price.replace(new RegExp('\\$', 'g'), '')) : 0
			    		}]
			    	}
			    
			    	setProduct(product)
						setVariants(variants)
						setSelectedVariant(variants.find(v=>v.available))

			    } catch(e) {

			    }
		}
	}

	const buyNow = () => {
		Analytics.logEvent("add_to_cart", product.id)
		Analytics.sendfb_AddToCart(product)

		CartManager.add(product, selectedVariant)
		props.onClose(true)
		props.onAddProduct()
	}

	const variantsData = () => {
		const allOption1s = Array.from(new Set(variants.map(v=>v.option1)))
		const availOption1s = Array.from(new Set(variants.filter(v=>v.available).map(v=>v.option1)))

		const allOption2s = Array.from(new Set(variants.map(v=>v.option2)))
		const availOption2s = Array.from(new Set(variants.filter(v=>v.available && v.option1 == selectedVariant.option1).map(v=>v.option2)))

		const allOption3s = Array.from(new Set(variants.map(v=>v.option3)))
		const availOption3s = Array.from(new Set(variants.filter(v=>v.available && v.option1 == selectedVariant.option1 && v.option2 == selectedVariant.option2).map(v=>v.option3)))

		return {
			option1: allOption1s,
			option1Avail: availOption1s,
			option2: allOption2s,
			option2Avail: availOption2s,
			option3: allOption3s,
			option3Avail: availOption3s
		}
	}

	const findVariantWithOptions = (option1, option2, option3) => {
		return variants.filter(variant => variant.option1 === option1 && variant.option2 === option2 && variant.option3 === option3)[0]
	}

	const variantsSection = (product) => {
		if(!product) return <span/>
		const variantData = variantsData(product)

		if(variantData.option1.length <= 1 && variantData.option2.length <= 1 && variantData.option3.length <= 1) return <span/>

		const { data } = props
		return(
			<div style={{width:'100%'}}>
			{ variantData.option1.length > 1 && <PickerMenu styleData={styleData} title={product.options[0]} key='dropdown' options={variantData.option1} availOptions={variantData.option1Avail} value={selectedVariant.option1 || variantData.option1[0]} onChange={(option)=>{
				let newSelectedVariant = findVariantWithOptions(option, selectedVariant.option2, selectedVariant.option3)
				// TODO: what if 2nd option is selected, and currently selected third option no longer available?  edge case that needs handling...
				setSelectedVariant(newSelectedVariant)
			}}/> }
			{ variantData.option2.length > 1 && <PickerMenu styleData={styleData} title={product.options[1]} key='dropdown' options={variantData.option2} availOptions={variantData.option2Avail} value={selectedVariant.option2 || variantData.option2[0]} onChange={(option)=>{
				let newSelectedVariant = findVariantWithOptions(selectedVariant.option1, option, selectedVariant.option3)
				setSelectedVariant(newSelectedVariant)
			}}/> }
			{ variantData.option3.length > 1 && <PickerMenu key='dropdown' title={product.options[2]} options={variantData.option3} availOptions={variantData.option3Avail} value={selectedVariant.option3 || variantData.option3[0]} onChange={(option)=>{
				let newSelectedVariant = findVariantWithOptions(selectedVariant.option1, selectedVariant.option2, option)
				setSelectedVariant(newSelectedVariant)
			}}/> }
			</div>
		)
	}

	const getFloatingButton = (transitionState) => {
		let isAddToCartInline = false
		try {
			isAddToCartInline = styleData.productView.addToCart.inline
		} catch(e) {}

		if (isAddToCartInline) return null

		const button = <Button transitionState={transitionState} styleData={context.state.styleData} onClick={()=>{buyNow()}} fixedFooter disabled={!selectedVariant || !selectedVariant.available}>
				{addToCartDisabled ? "OUT OT STOCK" : "ADD TO CART"}
				</Button>

		return button
	}

	const tooltipContents = () => {
		let isAddToCartInline = false
		try {
			isAddToCartInline = styleData.productView.addToCart.inline
		} catch(e) {}

		const addToCartDisabled = !selectedVariant || !selectedVariant.available

		const getDetails = () => {
			return <div class="details">

			<div class="detail-header bottom">
			<h3 class="product-title">{product.title}</h3>
			{ (styleData.name == "sparrow") && <img class="separator" src="/zigzag.svg"/> }
			<h4 class="product-price">{`${props.currencySymbol}${selectedVariant.price.toFixed(2)}`}</h4>
			</div>

			{ variantsSection(product) }

			{isAddToCartInline && <p><Button noShadow slideUpIn fullWidth styleData={styleData} onClick={()=>{buyNow()}} disabled={addToCartDisabled}>
				{addToCartDisabled ? "OUT OT STOCK" : "ADD TO CART"}
			</Button></p>}

			<div class="description" dangerouslySetInnerHTML={{__html: product.description}}>
			</div>
			</div>
		}

		let leftArrowPath, rightArrowPath
		try{
			switch(styleData.productView.photos.arrows) {
				case "minimalist":
					leftArrowPath = "/left-arrow-minimalist.svg"
					rightArrowPath = "/right-arrow-minimalist.svg"
					break
				case "minimalist2":
					leftArrowPath = "/left-arrow-minimalist-2.svg"
					rightArrowPath = "/right-arrow-minimalist-2.svg"
					break
				case "basic":
				default:
					leftArrowPath = "/left-arrow.svg"
					rightArrowPath = "/right-arrow.svg"
					break
			}
		} catch(e) {
			leftArrowPath = "/left-arrow.svg"
			rightArrowPath = "/right-arrow.svg"
		}

		return(
			<Tooltip
			style={{"backgroundColor": !!product ? "transparent":"white"}}>
			<Scroll
			ref={scrollRef}
			styleData={styleData}
			onTouchEnd={(e)=>{
				if(scrollRef.current.scrollTop < -50) {
					scrollRef.current.style.marginTop = -e.target.scrollTop + "px"
					props.onClose()
				}
			}}>

			<PullHandle styleData={styleData}/>

			<ProductImagesContainer styleData={styleData}>
			<div class="controls-container">
			<button class="left" onClick={()=>{
				swiperRef.current.swiper.slidePrev(250, false)
			}}><img src={leftArrowPath}/></button>
			<button class="right" onClick={()=> swiperRef.current.swiper.slideNext() }><img src={rightArrowPath}/></button>
			</div>
			<Swiper 
			ref={swiperRef}
			pagination={false}
			loop={true}
			spaceBetween={0}
			slidesPerView={1}>
			{
				product && product.images.map(image => <SwiperSlide><ProductImage styleData={styleData} src={image}/></SwiperSlide>)
			}
			</Swiper>
			</ProductImagesContainer>
		
			{ product && getDetails() }

			</Scroll>
			</Tooltip>
			)

	}

let isAddToCartInline = false
		try {
			isAddToCartInline = styleData.productView.addToCart.inline
		} catch(e) {}
const addToCartDisabled = !selectedVariant || !selectedVariant.available

		return(
			<CSSTransition
			onExited={()=>{
				setProduct(null)
				setSelectedVariant(null)
				setVariants(null)
			}}
      in={props.visible}
      timeout={250}
      unmountOnExit 
      mountOnEnter>
      {(transitionState) => {
      	return(
      	<Background
      	key={product ? product.id : null}
      	transitionState={transitionState}
      	onClick={e=>{
      		if (e.target === e.currentTarget ) {
      			props.onClose()
      		}
      	}}>
    		<Container 
    		styleData={context.state.styleData}
    		transitionState={transitionState}>
					{ tooltipContents() }
					{ getFloatingButton(transitionState) }
				</Container>

        </Background>
        )
      }}
			</CSSTransition>
		)

}