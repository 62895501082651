import localforage from "localforage"
import 'regenerator-runtime/runtime'
import memoize from "memoize-one"

class CartManager {
	constructor(){
		if(! CartManager.instance){
			localforage.config({
		      driver      : [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
		      name        : 'freckle',
		      version     : 1.0,
		      size        : 4980736, 
		      storeName   : 'cartStore',
		      description : ''
		    })
		    this.storeId = null
		    this.items = {}

			CartManager.instance = this;
		}

		return CartManager.instance;
	}

	clearCart = async () => {
		await CartManager.instance.persistCart([])
	}

	setStoreId = (storeId) => {
		if(this.storeId === storeId) return
		this.storeId = storeId
	    // load cart from localstorage if exists
	    const key = `cart-${storeId}`
	    localforage.getItem(key).then(res => {
	    	if(res) CartManager.instance.items = JSON.parse(res)
	    	document.dispatchEvent(new CustomEvent('freckleCartLoaded', {bubbles: true, detail: {}}))
	    })
	}

	persistCart = async (items) => {
		if(!CartManager.instance.storeId) return
		const key = `cart-${CartManager.instance.storeId}`
		const payload = JSON.stringify(items)
		await localforage.setItem(key, payload)
	}

	add = (item, variant) => {
		const key = item.id + ":" + variant.id
		if(key in this.items) {
			const currentQuantity = this.items[key].quantity
			this.items[key] = {...this.items[key], quantity: currentQuantity+1 }
		} else {
			this.items[key] = {...item, variant: variant, quantity: 1 }
		}
		document.dispatchEvent(new CustomEvent('freckleCartUpdated', {bubbles: true, detail: {}}))
		this.persistCart(this.items)
	}

	remove = (item, variant, all=false) => {
		const key = item.id + ":" + variant.id
		if(key in this.items) {
			const currentQuantity = this.items[key].quantity
			if(all || currentQuantity == 1) {
				delete this.items[key]
			} else if(currentQuantity > 0){
				this.items[key] = {...this.items[key], quantity: currentQuantity-1 }
			}
		}
		document.dispatchEvent(new CustomEvent('freckleCartUpdated', {bubbles: true, detail: {}}))
		this.persistCart(this.items)
	}

	cartValue = () => {
		let products = this.items
		let cartSubtotal = 0
		if(Object.keys(products).length > 0) cartSubtotal = Object.keys(products).map(key => products[key].quantity * products[key].price).reduce((a,b)=> parseInt(a)+parseInt(b)).toFixed(2)
		return cartSubtotal
	}

	allItems = () => {
		return this.items || {}
	}

	allItemIds = () => {
		return this.items ? Object.values(this.items).map(item=> item.id) : []
	}

	count = () => {
		return this._count(this.items)
	}

	_count = (items) => {
		return Object.values(items).map(item => item.quantity).reduce((a,b)=>a+b, 0)
	}

	// _count = memoize((items) => {
	// 	return Object.values(items).map(item => item.quantity).reduce((a,b)=>a+b, 0)
	// })

}

const instance = new CartManager()

export default instance