export const playBlack = `
<svg width="25px" height="36px" viewBox="0 0 25 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-211.000000, -177.000000)" fill="#000000">
            <g id="play" transform="translate(211.000000, 177.000000)">
                <polygon id="Path-2" points="0 0 25 18.4 0 36"></polygon>
            </g>
        </g>
    </g>
</svg>
`

export const unmuteBell = `<svg height="16" viewBox="0 0 12 16" width="12" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="m8.97226659.5860734c.13218407-.11235646.29132356-.18839314.46178397-.22063988.54267553-.10266033 1.06582364.25404248 1.16848394.79671802l-.0000281.00000531c.4316624 2.28182236.6474936 4.56110341.6474936 6.83784315 0 2.2469719-.2102242 4.3913069-.6306726 6.4330052-.0318566.1546955-.0999041.2996448-.1985694.4229764-.344993.4312412-.97425515.5011593-1.40549641.1561663l-3.76526159-3.0121479c.66666667-1.375 1-2.75 1-4.125s-.33333333-2.75-1-4.125zm-4.47226659 3.1639266c.66666667 1.42241772 1 2.83908438 1 4.25s-.33333333 2.7442489-1 4l-1.04686928-.0163573c-1.6383794-.0255997-2.95313072-1.3610545-2.95313072-2.99963389v-2.21801762c0-1.63857939 1.31475132-2.97403418 2.95313072-2.99963386z" fill-rule="evenodd"/></svg>`

export const replayBlack = `
<svg width="37px" height="42px" viewBox="0 0 37 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-150.000000, -181.000000)">
            <g id="Group" transform="translate(152.000000, 181.000000)">
                <path d="M16.5,40 C25.6126984,40 33,32.6126984 33,23.5 C33,14.3873016 25.6126984,7 16.5,7 C7.38730163,7 0,14.3873016 0,23.5" id="Oval" stroke="#000000" stroke-width="3" transform="translate(16.500000, 23.500000) rotate(90.000000) translate(-16.500000, -23.500000) "></path>
                <polygon id="Path" fill="#000000" points="16.5 0 6.5 6.75 16.5 13.5"></polygon>
            </g>
        </g>
    </g>
</svg>
`

export const play = `
<svg width="10px" height="15px" viewBox="0 0 10 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-520.000000, -568.000000)" fill="#ffffff">
            <g id="Group-11" transform="translate(298.000000, 567.000000)">
                <g id="Group-9" transform="translate(180.000000, 1.000000)">
                    <polygon id="Path-2" points="42 -3.55271368e-15 52 7.41111111 42 14.5"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>
`

export const pause = `
<svg width="9px" height="15px" viewBox="0 0 9 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-527.000000, -568.000000)" fill="#ffffff">
            <g id="Group-11" transform="translate(298.000000, 567.000000)">
                <g id="Group-9" transform="translate(180.000000, 1.000000)">
                    <path d="M49,0 L52,0 L52,15 L49,15 L49,0 Z M55,0 L58,0 L58,15 L55,15 L55,0 Z" id="Combined-Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`

export const vol = `
<svg width="12px" height="13px" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-52.000000, -57.000000)" fill="#FFFFFF">
            <g id="Group-3-Copy" transform="translate(51.000000, 57.000000)">
                <path d="M5,6.59619408 L2,9.59619408 L2,3.5 C2,2.94771525 2.44771525,2.5 3,2.5 L5,2.5 L5,6.59619408 Z M5,10.8388348 L5,11 L4.83883476,11 L5,10.8388348 Z M10,1.59619408 L6,5.59619408 L6,2.5 L8.4,0.7 C8.57309617,0.570177872 8.78362979,0.5 9,0.5 C9.55228475,0.5 10,0.94771525 10,1.5 L10,1.59619408 Z M10,5.83883476 L10,12 C10,12.2163702 9.92982213,12.4269038 9.8,12.6 C9.46862915,13.0418278 8.8418278,13.1313708 8.4,12.8 L6,11 L6,9.83883476 L10,5.83883476 Z" id="Combined-Shape"></path>
                <rect id="Rectangle" transform="translate(6.646447, 7.056854) rotate(45.000000) translate(-6.646447, -7.056854) " x="6.14644661" y="-0.443145751" width="1" height="15"></rect>
            </g>
        </g>
    </g>
</svg>
`

export const volMuted = `
<svg width="8px" height="13px" viewBox="0 0 8 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-53.000000, -57.000000)" fill="#FFFFFF">
            <g id="Group-3-Copy" transform="translate(51.000000, 57.000000)">
                <path d="M2,3.5 C2,2.94771525 2.44771525,2.5 3,2.5 L5,2.5 L5,11 L3,11 C2.44771525,11 2,10.5522847 2,10 L2,3.5 Z M6,2.5 L8.4,0.7 C8.57309617,0.570177872 8.78362979,0.5 9,0.5 C9.55228475,0.5 10,0.94771525 10,1.5 L10,12 C10,12.2163702 9.92982213,12.4269038 9.8,12.6 C9.46862915,13.0418278 8.8418278,13.1313708 8.4,12.8 L6,11 L6,2.5 Z" id="Combined-Shape"></path>
            </g>
        </g>
    </g>
</svg>
`

export const whitefreckle = `
<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Artboard" transform="translate(-762.000000, -333.000000)" fill="#FFFFFF" fill-rule="nonzero">
        <path d="M762.223016,344 L767.535129,344 C768.226748,345.195603 769.519435,346 771,346 C772.480565,346 773.773252,345.195603 774.464871,344 L779.776984,344 C778.867525,348.007977 775.283175,351 771,351 C766.716825,351 763.132475,348.007977 762.223016,344 Z M762.054929,341 C762.552373,336.50005 766.367446,333 771,333 C775.632554,333 779.447627,336.50005 779.945071,341 L774.873983,341 C774.42994,339.274773 772.863839,338 771,338 C769.136161,338 767.57006,339.274773 767.126017,341 L762.054929,341 Z" id="Combined-Shape"></path>
    </g>
</g>
</svg>
`

export const bluefreckle = `
<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <radialGradient cx="50%" cy="0%" fx="50%" fy="0%" r="100%" id="radialGradient-1">
            <stop stop-color="#80C3F3" offset="0%"></stop>
            <stop stop-color="#4A90E2" offset="100%"></stop>
        </radialGradient>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-762.000000, -333.000000)" fill="url(#radialGradient-1)">
            <circle id="Oval" cx="771" cy="342" r="9"></circle>
        </g>
    </g>
</svg>
`