import React, { createContext, useContext, useState, useEffect, useReducer } from 'react'
import * as Analytics from '../helpers/analytics';

import CartManager from '../helpers/cart-manager'

const axios = require('axios')

const AppContext = createContext();

const AppAction = Object.freeze({
	setVideoData: "setVideoData",
	setStyleData: "setStyleData",
	setAllProducts: "setAllProducts",
	setEmbedMode: "setEmbedMode",
})

export function FreckleAppContext(props) {
	const [state, dispatch] = useReducer((state, action)=>{
		switch(action.type) {
			case AppAction.setVideoData:
			return {
				...state, 
				videoData: action.payload,
			};

			case AppAction.setStyleData:
			return {
				...state, 
				styleData: action.payload,
			};

			case AppAction.setAllProducts:
			return {
				...state, 
				allProducts: action.payload,
			};

			case AppAction.setEmbedMode:
			return {
				...state, 
				embedMode: action.payload,
			};
		}
	}, 
	{
		videoData: null,
		styleData: null,
		allProducts: null,
		embedMode: false,
	});

	const setEmbedMode = (val) => {
		dispatch({ type: AppAction.setEmbedMode, payload: val });
	}

	const listenForBootstrapPayload = (event) => {
		const { data } = event
		if(!!data.styleData && !!data.videoData){
			CartManager.setStoreId(this.props.siteid || "test")
			if(!!data.clearCart) CartManager.clearCart()

			let _allProducts = data.videoData.products
			if(!!data.styleData.carousel){
				_allProducts = _allProducts.concat(Object.values(data.styleData.carousel.products))
			}

			if (!state.videoData) {
				dispatch({ type: AppAction.setVideoData, payload: data.videoData });
			}
			dispatch({ type: AppAction.setStyleData, payload: data.styleData });
			dispatch({ type: AppAction.setAllProducts, payload: _allProducts });
		}
	}

	useEffect(()=>{
		window.addEventListener("message", listenForBootstrapPayload, false)

		return ()=>{
			window.removeEventListener("message", listenForBootstrapPayload, false)
		}
	}, [state.styleData, state.videoData, state.allProducts])

	const loadSite = async (siteid, logView=false, embed=false) => {
		const resp = await axios.get(`https://stream.freckle.shop/${siteid}/manifest.json?t=${new Date()}`)

		let _allProducts = resp.data.videoData.products
		if(!!resp.data.styleData.carousel){
			_allProducts = _allProducts.concat(Object.values(resp.data.styleData.carousel.products))
		}

		if(!!resp.data.styleData.pageTitle && !embed){
			document.title = resp.data.styleData.pageTitle
		}

		const _styleData = resp.data.styleData
		const _videoData = resp.data.videoData

		Analytics.setMicrositeId(props.siteid)
		if (logView) {
			Analytics.trackView();
		}

		if(!!_styleData && !!_styleData.analytics){
		// set google analytics id if it is included here
		if(!!_styleData.analytics.google && _styleData.analytics.google.enabled && !!_styleData.analytics.google.trackid) {
			Analytics.setGoogleAnalyticsId(_styleData.analytics.google.trackid)
		}
		// set facebook pixel id if its included here and facebook tracking is enabled
		if(_styleData.analytics.facebook.enabled && !!_styleData.analytics.facebook.fbpixelid){
			Analytics.setFacebookTrackingEnabled(true)
			Analytics.setFacebookPixelId(_styleData.analytics.facebook.fbpixelid)
		}
		if(_styleData.analytics.freckle.enabled) {
			Analytics.setTrackingEnabled(true)
		}
		// if cookie consent banner is not activated, simply grant tracking access
		if(!_styleData.analytics.showCookieConsent){
			Analytics.consentToTracking()
		}
	}

	dispatch({ type: AppAction.setVideoData, payload: _videoData });
	dispatch({ type: AppAction.setStyleData, payload: _styleData });
	dispatch({ type: AppAction.setAllProducts, payload: _allProducts });
}

return (
	<AppContext.Provider value={{state, loadSite, setEmbedMode, dispatch}}>
	{props.children}
	</AppContext.Provider>
	)
}

export function useAppContext() {
	return useContext(AppContext)
}

