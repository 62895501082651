// import style from './style.scss';
import { h, Component, createRef } from 'preact'

import * as React from 'preact';
import * as ReactDOM from 'preact';

import axios from 'axios'
import memoize from "memoize-one"
import Freckle from './freckle'
import ScrubBar from './scrubbar'
import Product from '../product'

import ViewProductsButton from './view-products-button'

import register from 'preact-custom-element';

import ReactResizeDetector from 'react-resize-detector';

import * as Analytics from '../../helpers/analytics'

import { CSSTransition } from 'react-transition-group'

import { play, pause, volMuted, vol, playBlack, replayBlack, unmuteBell } from './button-vectors'

import Hls from 'hls.js'

import styled from 'styled-components';

const LOADING = 0
const LOADED = 1
const LOAD_FAIL = 2

const mobile = require('is-mobile');

// TODO: get media queries, video style, etc transferred over from scss

const Video = styled.video`
object-fit: cover;
pointer-events: none;
outline: none; 
background-color: black;
padding: 0;
margin: 0;

position: absolute;
top: 0;
left: 0;

object-fit: contain;

width: 100%;
height: 100%;

// border: 1px solid red;

@media (min-aspect-ratio: 1/1) {
  min-height: 1px;
  object-fit: contain;
}`

const Container = styled.div`
display: inline-block;
background-color: black;
position: relative;

${props=>{
  if (props.embed) {
    return (
    `
height: 100%;
width: 100%;
max-height: 100%`
    );
  }
  return '';
}}
`

const FreckleLayer = styled.div`
position: absolute;
top: 50%;
left: 50%;
width: 100%;
height: 100%;
z-index: 20;
overflow: hidden;`

const VideoContainer = styled.div`
display:flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
// width: 100%;
// height:100%;
position: relative;

width: 100vw;
padding-top: ${props=> 'calc(' + props.aspectRatio + ' * 100%)' };

@media (min-aspect-ratio: 1/1) {
  padding-top: 0;
  height: max(min(50vh, 800px), 400px);
}
`

const ControlsSuperContainer = styled.div`
height: 100%;
width: 100%;
position: absolute;
top: 0;
left: 0;`

const ControlsContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: calc(100%);
z-index: 40;
position: absolute;
bottom: -18px;

button{
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 40px;
  width: 40px;
  border-radius: 25px;

  ${({styleData})=>{
    switch(styleData.playback.controlsStyle) {
      case "minimalist":
      return`
position: absolute;
bottom: 25px;
left: 3px;
background-color: transparent;
box-shadow: none;`

        break;
      case "basic":
      default:
        return`
background-color: #111111;
box-shadow: 0px 3px 10px rgba(0,0,0,0.25);`

        break;
    }
  }};

  display: flex;
  justify-content: center;
  align-items: center;

  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;

  transition: 0.2s;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}`

const GhostMuteButton = styled.button`
outline: 0;
border: 0;
background-color: rgba(0, 0, 0, 0.8);
position: absolute;
top: 10px;
left: 10px;
padding: 10px;
display: flex;
justify-content: center;
align-items: center;
z-index: 21;
transition: 0.15s;
border-radius: 1000px;
cursor: pointer;

label {
  color: white;
  font-family: Arial;
  font-weight: bold;
  font-size:12px;
  line-height: 13px;
  padding-left: 7px;
  padding-right: 5px;
  user-select: none;
  pointer-events: none;
}`

const LoadingContainer = styled.div`
height: 100%;
width: 100%;
border-radius: 2.5px;
overflow: hidden;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;

.lds-ring.small {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-top: -8px;
}

.lds-ring.small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 8px;
  border: 8px solid #4A90E2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4A90E2 transparent transparent transparent;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`

const PlayOverlay = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(0,0,0,0.1);
pointer-events: ${props=> props.hidden ? 'none':'all'};
opacity: ${props=> props.hidden ? 0:1};
z-index: 40;

button {
  height: 80px;
  width: 80px;
  background-color: rgba(255,255,255,0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  transition: 0.2s;
}

button:hover {
  cursor: pointer;
  background-color: rgba(255,255,255,1);
  transform: scale(0.95);
}
`

export default class Player extends React.Component {

  constructor(props, context) {
  	super(props, context);

  	this.state = {
      template: null,
      shopBase: null,
      playing: false,
      hasBegunPlayback: false,
      products: [],
      duration: null,
      selectedFreckleId: null,
      wasPlayingOnSelect: false,
      loading: LOADING,
      playbackId: null,
      aspectRatio: 0,
      freckleLayerHeight: 0,
      freckleLayerWidth: 0,
      showsControls: true,
      canPlay: false,
      waitingToPlay: (!eval(props.autoplay) || !eval(props.muted)),
      videoEnded: false, 
      videoHeight: 0,
      videoWidth: 0,
      videoId: this.props.videoid,
      suspended: false,
      numberVisibleProducts: 0,
      viewProductsCollapsed: false,
      passedIntroThresh: false,
    };

    this.controlsTimer = null

    this.videoPlayer = null
  	// this.videoPlayer = createRef();

    this.freckleRefs = {}
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // antipattern but...eh
  componentWillReceiveProps(nextProps) {
    if(this.props.data != nextProps.data && nextProps.local) {
      this.handleData(nextProps.data)
    }
  }

  componentDidMount() {
    if(this.props.data !== null) {
      this.handleData(this.props.data)
    }

    this.controlsTimer = setTimeout(()=>{
      this.setState({showsControls: false})
      this.controlsTime = null
    }, 1500)

    this.loadHLSVideo()

    document.addEventListener("shouldPauseVideo", ()=>{
      this.videoPlayer.pause()
    }, false)

    document.addEventListener("shouldPlayVideo", ()=>{
      this.videoPlayer.play()
    }, false)
  }

  loadHLSVideo = () => {
    if(!!!this.videoPlayer) return
    const hlsUrl = `https://stream.freckle.shop/${this.props.videoid}/index.m3u8`
    const mp4Url = `https://stream.freckle.shop/${this.props.videoid}/mp4/base.mp4`
    const isHlsSupported = Hls.isSupported()
    if (isHlsSupported) {
      var video = this.videoPlayer
      var hls = new Hls();
        // bind them together
        hls.attachMedia(video);
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          hls.loadSource(hlsUrl);
        });
      } else {
        const source = document.createElement("source")
        source.src = mp4Url;
        this.videoPlayer.appendChild(source)
        this.videoPlayer.load()
      }
    }

  handleData = (data) => {
    if(data !== null) {
      this.setState({products: data.products, loading: LOADED, playbackId: data.playbackId, template: data.styleData}, ()=>{
        this.configureEventListeners()
        // requestAnimationFrame(this.updateLocations.bind(this))
        setTimeout(this.updateLocations.bind(this), 5)
        this.loadHLSVideo()
      })
    }
  }

  generateAnimationCSS = memoize(
    (products, duration) => {
      if(!products || !duration) return ""
      let animationIncrement = 0.001
      let keys = products.map((group)=>{ return group.id })
      
      let animations = keys.map(key => {
        let frames = products.find((group)=>{ return group.id === key }).locs.map(frameArr =>{
          return {ts: frameArr[0]/duration, t: frameArr[1], x: frameArr[2], y: frameArr[3]}
        }) || []
        let cssFrames = frames.map(frame => {
            let percent = frame.ts * 100
            switch(frame.t) {
              case 0:            
                return `${Math.max(percent-animationIncrement, 0)}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 0.0; transform: scale(0); } 
              ${percent}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 1.0; transform: scale(1); }`
                break;
              case 1:
                  return `${percent}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 1.0; transform: scale(1); }`
                break;
              case 2:
                return `${percent}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 1.0; transform: scale(1); } 
              ${percent+animationIncrement}% { opacity: 0.0; transform: scale(0); }`
                break;  
              default:
                break;
            }
        })
        const lastFrame = frames[frames.length-1]
        if(!!lastFrame && lastFrame.t !== 2){
          cssFrames.push(`${100-animationIncrement}% { left: ${lastFrame.x*100}%; top: ${(lastFrame.y)*100}%; opacity: 1.0; transform: scale(1); }`)
        }
        let animationString = `@keyframes anim-${key}-freckle { 0% { opacity: 0.0; transform: scale(0); } ${cssFrames.join("\n")} 100% { opacity: 0.0; transform: scale(0); } }`
        return animationString

      })
      const full = animations.join("\n")
      return full
    }
  )

  selectedFreckle = (freckleId, freckleRef) => {
    this.videoPlayer.pause()
    document.dispatchEvent(new CustomEvent('freckleProductSelected', {bubbles: true, detail: {"productId": freckleId}}))
    this.props.onSelectedFreckle(freckleId)
  }

  updateLocations = () => {
    const offset = -this.videoPlayer.currentTime 
    Object.values(this.freckleRefs).forEach((freckle)=>{
      freckle.current.style.animationDelay = offset + "s"
    })

    const numProducts = this.getNumberOfProducts()
    if (numProducts != this.state.numberVisibleProducts) {
      this.setState({ numberVisibleProducts: numProducts })
    }

    const viewProductsCollapsed = ((this.videoPlayer.currentTime > 3 || this.state.passedIntroThresh ) && this.state.playing)
    if (viewProductsCollapsed != this.state.viewProductsCollapsed) {
      this.setState({viewProductsCollapsed: viewProductsCollapsed});
    }

    if (!this.state.passedIntroThresh && this.videoPlayer.currentTime > 3) {
      this.setState({passedIntroThresh: true})
    }

    // requestAnimationFrame(this.updateLocations.bind(this))
    setTimeout(this.updateLocations.bind(this), 5)
  }

  togglePlay = (e) => {
    if(this.state.playing){
      return this.videoPlayer.pause()
    }else{
      return this.videoPlayer.play()
    }
  }

  configureEventListeners = () => {
    const player = this.videoPlayer

    player.addEventListener("suspend", ()=>{
      this.setState({suspended: true})
    })

    player.addEventListener("timeupdate", ()=>{
      Analytics.setCurrentVideoTime(this.videoPlayer.currentTime)
      this.setState({suspended: false})
    }, true)

    player.addEventListener("play", () => { 
      this.setState({playing: true, waitingToPlay: false, videoEnded: false, hasBegunPlayback: true, suspended: false})
    }, true);

    player.addEventListener("ended", () => { 
      if(!eval(this.props.loop)) this.setState({playing: false, waitingToPlay: false, videoEnded: true, suspended: false})
    }, true);

    player.addEventListener("pause", () => { 
      this.setState({playing: false})
    }, true);

    player.addEventListener("canplay", (e) => { 
      const height = this.videoPlayer.videoHeight
      const width = this.videoPlayer.videoWidth
      const aspectRatio = height / width

      if(this.props.onCanplay) this.props.onCanplay({height: height, width: width})

      this.setState({canPlay: true, duration: player.duration, videoHeight: height, videoWidth: width, aspectRatio: aspectRatio}, ()=>{
        this.resizedVideo()
      })
    })
  }

  tappedBackground = (e) => {
    if(e.target.className === `tooltipBlur tooltipContainer` || e.target.className === `product-page-container` || e.target.className === "exit-button") {
      this.setState({selectedFreckleId: null})
      if(this.state.wasPlayingOnSelect) this.videoPlayer.play()
    }
  }

  closeProductView = (preventPlay=false) => {
    this.setState({selectedFreckleId: null})
    if(this.state.wasPlayingOnSelect && !preventPlay) this.videoPlayer.play()
  }

  scrub = (value) => {
    if(this.state.playing) this.videoPlayer.pause()
    this.videoPlayer.currentTime = this.videoPlayer.duration * value
  }

  productUrl = (handle) => {
    return `https://${this.state.shopBase}/products/${handle}`
  }

  resizedVideo = () => {
    const player = this.videoPlayer
    var height = player.getBoundingClientRect().height
    var width = player.getBoundingClientRect().width

    const aspectRatio = this.state.aspectRatio
    const containerAspectRatio = height / width
    
    if(aspectRatio >= containerAspectRatio) {
      width = height / this.state.aspectRatio
    } else {
      height = this.state.aspectRatio * width
    }

    this.setState({freckleLayerHeight: height, freckleLayerWidth: width})
  }

  tappedVideo = (e) => {
    if(e.target.className !== 'freckleLayer') return
    if(mobile()){
      this.tapControls()
    } else {
      this.togglePlay(e)
    }
  }

  test = (e) => {
    if(!mobile()) this.tapControls()
  }

  tapControls = () => {
    this.setState({showsControls: true})
    if(!this.state.showsControls){
      this.setState({showsControls: true})
      if(this.controlsTimer) clearTimeout(this.controlsTimer)
      this.controlsTimer = setTimeout(()=>{
        this.setState({showsControls: false})
        this.controlsTime = null
      }, 3000)
    } else {
      if(mobile()){
        if(this.controlsTimer) clearTimeout(this.controlsTimer)
        this.setState({showsControls: false})
      } 
    }
  }

  getNumberOfProducts = () => {
    let numVisibleProducts = 0
    this.state.products.forEach(product=> {
      if (product.itemType == 'list') {
        const hasActiveRange = !!product.ranges.filter(range=>{
          return this.videoPlayer.currentTime >= range.start && this.videoPlayer.currentTime <= range.end 
        }).length
        if (hasActiveRange) numVisibleProducts += 1
      }

      if (!product.itemType || product.itemType == 'hotspot') {
        const lastKf = [...product.locs].reverse().find(kf=> kf[0] <= this.videoPlayer.currentTime)
        if (lastKf) {
          const hasActiveHotspot = [0,1].includes(lastKf[1])
          if (hasActiveHotspot) numVisibleProducts += 1 
        } 
      }
    })
    return numVisibleProducts
  }

  getVisibleProducts = () => {
    let visibleProducts = []
    this.state.products.forEach(product=> {
      if (product.itemType == 'list') {
        const hasActiveRange = !!product.ranges.filter(range=>{
          return this.videoPlayer.currentTime >= range.start && this.videoPlayer.currentTime <= range.end 
        }).length
        if (hasActiveRange) visibleProducts.push(product)
      }

      if (!product.itemType || product.itemType == 'hotspot') {
        const lastKf = [...product.locs].reverse().find(kf=> kf[0] <= this.videoPlayer.currentTime)
        if (lastKf) {
          const hasActiveHotspot = [0,1].includes(lastKf[1])
          if (hasActiveHotspot) visibleProducts.push(product)
        } 
      }
    })
    return visibleProducts
  }

  getFreckles = memoize((products, duration, selectedFreckle, playing, pauseToShopEnabled) => {
    var dur = 0
    if(!products || !duration) return <span/>
    if(this.videoPlayer != null) dur = this.videoPlayer.duration

    return products.filter(product=> product.itemType == 'hotspot' || !product.itemType).map((product)=>{
      var freckleRef = this.freckleRefs[product.id]
      if(freckleRef == null) {
        freckleRef = createRef();
        this.freckleRefs[product.id] = freckleRef
      }

      const isSelected = product.id === this.state.selectedFreckleId
      const freckleName = "anim-" + product.id + "-freckle"

      const visible = !pauseToShopEnabled || (!playing && this.state.hasBegunPlayback)

      return (
        <Freckle 
        size={this.state.freckleLayerWidth * 0.095}
        visible={visible}
        trackRef={freckleRef} 
        freckleId={product.id} 
        durationSeconds={duration} 
        selected={this.selectedFreckle} 
        isSelected={isSelected}
        product={product}/>
      )
    })
  })

  getFreckleLayer = () => {
    if((this.state.loading === LOADING || this.state.suspended) && this.state.waitingToPlay) return <span/>
    return(
      <FreckleLayer style={{
        height: this.state.freckleLayerHeight+"px", 
        marginTop:-(this.state.freckleLayerHeight/2)+"px",
        width: this.state.freckleLayerWidth+"px",
        marginLeft:-(this.state.freckleLayerWidth/2)+"px",
        display: this.state.canPlay ? "block" : "none"
      }}>
        { 
          this.getFreckles(this.state.products, this.state.duration, this.state.selectedFreckleId, this.state.playing, eval(this.props.pauseToShopEnabled))
        }
        
        <ViewProductsButton
        styleData={this.props.styleData}
        onClick={(e)=>{
          this.videoPlayer.pause();
          this.props.shouldPresentProducts(this.getVisibleProducts())
        }}
        collapsed={this.state.viewProductsCollapsed}
        visible={!!this.state.numberVisibleProducts}
        count={this.state.numberVisibleProducts}>
        View Products
        </ViewProductsButton>

      </FreckleLayer>
    )
  }

  getControls = () => {
    if(!!!this.videoPlayer) return <span/>
    if(this.state.loading === LOADING) return <span/>
    const controlImage = this.state.playing ? pause : play
    const controlShift = this.state.playing ? "0px": "2px"
    const volumeImage = this.videoPlayer.muted ? vol : volMuted
    const volumeShift = this.videoPlayer.muted ? "0":"-1px"
    return(
      <ControlsContainer styleData={this.props.styleData}>
        <button onclick={this.togglePlay}>
          <img style={{"marginLeft":controlShift}} src={`data:image/svg+xml;base64,${btoa(controlImage)}`}/>
        </button>
        <ScrubBar player={this.videoPlayer} valueChanged={this.scrub}/>
      </ControlsContainer>
    )
  }

  getGhostMuteButton = () => {
    if(!!!this.videoPlayer) {
      return <span/>
    } else if(this.videoPlayer.muted) {
      return <GhostMuteButton
      onClick={()=>{
        this.videoPlayer.muted = !this.videoPlayer.muted
        this.forceUpdate()
      }}>
      <img style={{height: "13px", width: "13px"}} src={`data:image/svg+xml;base64,${btoa(unmuteBell)}`}/>
      </GhostMuteButton>
    } else {
      return <span/>
    }
  }

  getLoadingIndicator = () => {
    if(this.state.canPlay) return <span/>
    return(
      <LoadingContainer>
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </LoadingContainer>
    )
  }

  getPlayOverlay = () => {
    const icon = this.state.waitingToPlay || this.state.suspended ? playBlack : replayBlack
    const shift = this.state.waitingToPlay ? "5px" : "0"
    const hidden = !(this.state.waitingToPlay || this.state.videoEnded || this.state.suspended)
    return(
      <PlayOverlay hidden={hidden}>
        <button onClick={async (e)=>{
          await this.togglePlay(e)
          this.videoPlayer.muted = false
        }}>
          <img style={{height: "30px", width: "30px", marginLeft: shift}} src={`data:image/svg+xml;base64,${btoa(icon)}`}/>
        </button>
      </PlayOverlay>
    )
  }

  render() {
    if(this.state.loading === LOADING) {
      return (
        <Container embed={this.props.embed} style={this.props.style}>
          <LoadingContainer>
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </LoadingContainer>
        </Container>
      )
    } 
    else if(this.state.loading === LOAD_FAIL) {
      return <LoadingContainer><h1>Not Found</h1>Uh oh!  We weren't able to find your video...</LoadingContainer>
    }

    let freckleCss = this.generateAnimationCSS(this.state.products, this.state.duration)

    const containerStyle = {...this.props.style}

   	return(
        <Container embed={this.props.embed} onMouseMove={this.test} key={this.state.loading} style={containerStyle}>
          { this.getPlayOverlay() }
          <ReactResizeDetector handleWidth handleHeight onResize={this.resizedVideo}>
         		<VideoContainer 
            aspectRatio={this.state.aspectRatio}
            onClick={this.tappedVideo}>
              <style>{
                freckleCss
              }</style>
        			<Video
              aspectRatio={this.state.aspectRatio}
              key={this.props.videoid} ref={ node =>{
                this.videoPlayer = node
              }}
            controls={false} 
            autoPlay={eval(this.props.autoplay)} 
            playsInline 
            loop={eval(this.props.loop)}
            muted={eval(this.props.muted)}>
        			</Video>

              { this.getGhostMuteButton() }

              {
                this.getLoadingIndicator()
              }
              
              { 
                this.getFreckleLayer() 
              }
              <ControlsSuperContainer>
          			{ this.getControls() }
              </ControlsSuperContainer>

        		</VideoContainer>
          </ReactResizeDetector>

        </Container>
   	)
  }
}
register(Player, 'freckle-video', [], { shadow: true })

