import React from 'preact';
import { useState, useEffect } from 'preact/hooks';
import styled from 'styled-components';
import { ButtonStyle } from '../helpers/style-enums'

const Button = styled.button`
outline: 0;
border: 0;
font-family: ${props=> props.styleData.button.font} !important;
border-radius: ${props=>{
    let buttonRadius = '0px'
	switch(props.styleData.button.style){
	  case ButtonStyle.square:
	    buttonRadius = '0px'
	    break
	  case ButtonStyle.rounded:
	    buttonRadius = '6px'
	    break
	  case ButtonStyle.pill:
	    buttonRadius = '1000px';
	    break
	}
	return buttonRadius;
}} !important;
background-color: ${props=> props.styleData.button.color} !important;
color: ${props=> props.styleData.button.textColor} !important;
transform: scale(1.0);


text-decoration: none;
margin: 0;
outline: 0;
border: 0;
height: 50px;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.2s, color 0.2s, border 0.2s, opacity 0.25s, margin-top 0.25s;
font-size: 14px;
line-height: 14px;
font-weight: bold;

letter-spacing: 1px;

${props=>{
	if (props.disabled) {
		return `
background-color: #9f9f9f !important;
cursor: default;
user-select: none;
cursor: not-allowed;`
	}
}}

display: flex;
justify-content: center;
align-items: center;
${({fullWidth})=> fullWidth ? 'width: 100%;':null};

${props=>{
	if (props.transitionState) {
		return (props.transitionState === "entered" ? 'opacity: 1;' : 'opacity: 0;')
	}
}}

${props=>{
	if (props.fixedFooter) {
		return `position: fixed;
bottom: 10px;
left: 10px;
width: calc(100%  - 20px);`
	}
	return null;
}}

z-index: 100;

text-transform: uppercase;

background-color: #3781D8;
box-shadow: ${props=> props.noShadow ? 'none':'0px 5px 15px rgba(0, 0, 0, 0.15)' };`

export default Button