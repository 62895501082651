import React from 'react'
import styled from 'styled-components'
import { ButtonStyle } from '../../helpers/style-enums'

const SuperContainer = styled.div`
	width: 100vw;
	height: auto;
	position: fixed;
	left: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
`

const Container = styled.div`
	width: calc(100% - 70px);
	max-width: 700px;
	height: auto;
	padding: 20px;
	margin-bottom: 15px;
	background-color: white;
	border: 1px solid #efefef;
`

const H1 = styled.h1`
padding: 0;
margin: 0;
margin-bottom: 15px;
font-size: 16px;
`

const P = styled.p`
padding: 0;
margin: 0;
font-size: 14px;
line-height: 20px;
`


const Button = styled.button`
	outline: 0;
	border: 0;
	font-size: 14px;
	background-color: transparent;

	padding: 10px 20px;

	font-family: ${props=> props.styleData.button.font};
    border-radius: ${props=>{
    	let buttonRadius = '0px'
	    switch(props.styleData.button.style){
	      case ButtonStyle.square:
	        buttonRadius = '0px'
	        break
	      case ButtonStyle.rounded:
	        buttonRadius = '6px'
	        break
	      case ButtonStyle.pill:
	        buttonRadius = '1000px';
	        break
	    }
    	return buttonRadius
    }};
    background-color: ${props=> !!props.reject ? '#efefef' : props.styleData.button.color};
    color: ${props=> !!props.reject ? '#1f1f1f' : props.styleData.button.textColor};
    cursor: pointer;
    transition: 0.2s;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;
`

export default function CookieBanner(props){
	return(
		<SuperContainer>
		<Container>
		<H1>Cookies</H1>
		<P>This website uses cookies to enhance user experience and to analyze performance and traffic on our website. We also share information about your use of our site with our social media, advertising and analytics partners.</P>
		<ButtonContainer>
			<Button {...props} reject onClick={props.onDecline}>Decline</Button>
			<Button {...props} onClick={props.onAccept}>I Agree</Button>
		</ButtonContainer>
		</Container>
		</SuperContainer>
	)
}