import { uid } from 'uid';
import 'regenerator-runtime/runtime'
import ReactPixel from 'react-facebook-pixel';
import localforage from "localforage";
import CartManager from './cart-manager.js'
import ReactGA from 'react-ga';
const axios = require('axios')

let facebookPixelId = null

let googleAnalyticsId = null

let grantedPermission = false
let declinedPermission = false

let hasSentPageview = false

// these properties reflect store's settings, not customer choices
let facebookTrackingEnabled = false
let trackingEnabled = false

let micrositeUuid = null
let videoUuid = null
let sessionId = uid(32)

let currentVideoTime = 0

let previewMode = false

export function setPreviewMode(_previewMode=true){
	previewMode = _previewMode
}

export function setCurrentVideoTime(time){
	currentVideoTime = time
}

export function resetConsent() {
	grantedPermission = false
	declinedPermission = false
}

export async function setVideoId(_videoUuid) {
	videoUuid = _videoUuid
}

export async function setMicrositeId(_micrositeUuid) {
	micrositeUuid = _micrositeUuid

	const trackConsentId = `user-tracking-permissions-${micrositeUuid}`
	const trackConsentStoredResult = await localforage.getItem(trackConsentId)
	if(trackConsentStoredResult === true) {
		grantedPermission = true
	} else if(trackConsentStoredResult === false) {
		declinedPermission = true
	}
}

export function setFacebookTrackingEnabled(enabled=true){
	facebookTrackingEnabled = true
}

export function setTrackingEnabled(enabled=true){
	trackingEnabled = true
}

export function isTrackingEnabled(){
	return facebookTrackingEnabled || trackingEnabled
}

export function consented(){
	return grantedPermission
}

export function declined(){
	return declinedPermission
}

export function decline(){
	declinedPermission = true
}

export async function setFacebookPixelId(pixelId){
	// initialize the facebook pixel with supplied ID
	facebookPixelId = pixelId

	console.log("initialize fb with " + pixelId)

	ReactPixel.init(pixelId, {}, {
		autoConfig: true,
		debug: false,
	});
	
	// immediately revoke consent until user explicitly opts in.
	// ReactPixel.revokeConsent();
}

export async function setGoogleAnalyticsId(gaId){
	if(!gaId) return
	googleAnalyticsId = gaId
	ReactGA.initialize(googleAnalyticsId);
	ReactGA.pageview(window.location.pathname + window.location.search)
}

export function consentToTracking(){
	console.log("consented to tracking")
	if(!trackingEnabled) return

	grantedPermission = true
	
	ReactPixel.grantConsent()
	if(!hasSentPageview){
		ReactPixel.pageView()
	}

	hasSentPageview = true

	const trackConsentId = `user-tracking-permissions-${micrositeUuid}`
	localforage.setItem(trackConsentId, true)
}

export function sendfb_ViewContent(product){
	if(previewMode || !grantedPermission) return

	if(!!googleAnalyticsId) {
		ReactGA.event({
		  category: 'User',
		  action: 'Viewed Product',
		  label: product.title
		});
	}

	if(!!facebookPixelId){
		ReactPixel.track("ViewContent", {
			content_name: product.title,
			content_ids: [product.id],
			content_type: 'product',
			value: parseFloat(product.price)
		})
	}
}

export function sendfb_AddToCart(product){
	if(previewMode || !grantedPermission) return

	if(!!googleAnalyticsId) {
		ReactGA.event({
		  category: 'User',
		  action: 'Added To Cart',
		  label: product.title
		});
	}

	if(!!facebookPixelId){
		ReactPixel.track("AddToCart", {
			content_name: product.title,
			content_ids: [product.id],
			content_type: 'product',
			value: parseFloat(product.price)
		})
	}
}

export function sendfb_InitiateCheckout(){
	if(previewMode || !grantedPermission) return

	if(!!googleAnalyticsId) {
		ReactGA.event({
		  category: 'User',
		  action: 'Checkout'
		});
	}

	if(!!facebookPixelId){
		ReactPixel.track("InitiateCheckout", {})
	}
}

export async function trackView() {
	if(previewMode) return
	const payload = {
		eventName: "view",
		micrositeUuid: micrositeUuid,
		videoUuid: videoUuid,
		sessionId: sessionId,
		cartValue: CartManager.cartValue(),
		details: "{}"
	}
	return await axios.post('https://api.freckle.shop/event/log', payload)
}

export async function logEvent(eventName, productId=null, cartIds=null, details=null){
	if(previewMode) return
	const payload = {
		eventName: eventName,
		micrositeUuid: micrositeUuid,
		videoUuid: videoUuid,
		sessionId: sessionId,
		productId: productId,
		videoTime: currentVideoTime,
		cartValue: CartManager.cartValue(),
		cartIds: cartIds,
		details: !!details ? JSON.stringify(details) : null
	}
	return await axios.post('https://api.freckle.shop/event/log', payload)
}
