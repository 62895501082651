import { h, Component, createRef } from 'preact'
import * as React from 'preact';
import * as ReactDOM from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';

import styled from 'styled-components';

import { FreckleAppContext, useAppContext } from '../../context/state'

const ProgressSuperContainer = styled.div`
flex: 1;
background-color: transparent;`

const ProgressContainer = styled.div`
background-color: transparent;
height: 30px;
flex: 1;
overflow: visible;
cursor: pointer;
position: relative;
margin: 0 20px 0 0;

.knob {

   ${({styleData})=>{
    switch(styleData.playback.controlsStyle) {
      case "minimalist":
      return`
display: none;`

        break;
      case "basic":
      default:
        return`
height: 17px;
width: 17px;
background-color: white;
border-radius: 500px;
position: absolute;
right: -17px;
top: -6px;
opacity: 1;
box-shadow: 0px 1px 4px rgba(0,0,0,0.2);
pointer-events: none;`

        break;
    }
  }};
}`

const ProgressTrack = styled.div`
height: 5px;
width: 100%;
background-color: transparent;
pointer-events: none;
position: absolute;
left:0;

 ${({styleData})=>{
    switch(styleData.playback.controlsStyle) {
      case "minimalist":
      return`
top: 9px;`

        break;
      case "basic":
      default:
        return`
top:13px;`

        break;
    }
  }};`

const ProgressBar = styled.div`
 ${({styleData})=>{
    switch(styleData.playback.controlsStyle) {
      case "minimalist":
      return`
height: 3px;`

        break;
      case "basic":
      default:
        return`
height: 5px;`

        break;
    }
  }};

width: 100%;
background-color: white;
pointer-events: none;
position: absolute;
top:0;
left:0px;
background-color: ${props=>{
  let val = props.styleData.colors.accentColor
  try {
    if (props.styleData.playback.scrubBarColor) {
      val = props.styleData.playback.scrubBarColor
    }
  } catch(e) {}
  return val;
}} !important; `

export default function ScrubBar(props) {
  const context = useAppContext();

  const scrubBar = useRef(null);
  const scrubRef = useRef(null);
  const [scrubbing, setScrubbing] = useState(false);
  const [scrubOffset, setScrubOffset] = useState(null);

  const updateTime = (e) => {
    var perc = 0
    const player = props.player

    if(player.currentTime != null && player.duration != null) perc = player.currentTime / player.duration
    scrubRef.current.style.width = ((perc) * 100) + "%"

    requestAnimationFrame(updateTime)
  }

  const valueChanged = (offset) => {
    props.valueChanged(offset)
  }

  const mouseDown = (e) => {
    var rect = e.target.getBoundingClientRect();
    var xOffset = e.clientX - rect.left;
    let width = rect.width
    let scrubOffset = (xOffset / width)

    valueChanged(scrubOffset)
    setScrubbing(true);
  }

  const mouseMoved = (e) => {
    if(scrubbing){
      var rect = e.target.getBoundingClientRect();
      var xOffset = e.clientX - rect.left;
      let width = rect.width

      let scrubOffset = (xOffset / width)

      valueChanged(scrubOffset)
    }
  }

  const mouseCancel = (e) => {
    setScrubbing(false);
    setScrubOffset(null);
  }

  const touchStart = (e) => {
    var rect = e.target.getBoundingClientRect();
    var xOffset = e.touches[0].clientX - rect.left;
    let width = rect.width

    let scrubOffset = (xOffset / width)

    valueChanged(scrubOffset)

    setScrubbing(true);
  }

  const touchMove = (e) => {
    if(scrubbing){
      var rect = e.target.getBoundingClientRect();
      var xOffset = e.touches[0].clientX - rect.left;
      let width = rect.width

      let scrubOffset = (xOffset / width)

      valueChanged(scrubOffset)
    }
  }

  const touchCancel = (e) => {
    setScrubbing(false);
  }

  useState(()=>{
    requestAnimationFrame(updateTime)
  }, [])

	return (

    <ProgressSuperContainer>
      <ProgressContainer
        onMouseUp={mouseCancel} 
        onMouseLeave={mouseCancel} 
        onMouseDown={mouseDown} 
        onMouseMove={mouseMoved} 
        onTouchStart={touchStart}
        onTouchMove={touchMove}
        onTouchEnd={touchCancel}
        styleData={context.state.styleData}>
        <ProgressTrack styleData={context.state.styleData}>
                <ProgressBar styleData={context.state.styleData} ref={scrubRef}>
                  <div className="knob"></div>
                </ProgressBar>
        </ProgressTrack>
      </ProgressContainer>
    </ProgressSuperContainer>
	)

}