import * as React from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group'
import { useLockBodyScroll } from '../../helpers/useLockBodyScroll'
import { FreckleAppContext, useAppContext } from '../../context/state'

const Background = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background-color: ${({ transitionState }) => (transitionState === "entered" ? 'rgba(0,0,0,0.4)' : 'transparent')};
transition: 0.25s, width 0s, height 0s;
pointer-events: auto;
z-index: 1001;

@media (max-width: 1200px) {
	justify-content: flex-end;
}
`

const Label = styled.h3`
font-weight: 700;
font-size: 25px;
line-height: 40px;
color: black;
padding: 0;
margin: 0;
margin-left: 20px;
padding-bottom: 15px;
padding-top: 25px;
font-family: ${props=> props.styleData.headerText.font} !important;

 ${props=>{
    try {
      const headerText = props.styleData.carousel.headerText
      if (headerText.size) {
        return `
font-size: ${headerText.size}px;
font-weight: ${headerText.weight};
line-height: ${headerText.lineHeight || (headerText.size*1.5)}px;`
      }
      throw null
    } catch(e) {
      return `
font-weight: normal;
font-size: 25px; 
line-height: calc(40px * 1.5);`
    }
  }}

  ${props=>{
  	if (!!props.large) return `font-size: 22px;`
  }}
`

const Container = styled.div`
height: 80vh;
width: 100vw;
background-color: transparent;
margin-bottom: ${({ transitionState }) => (transitionState === "entered" ? '0' : '-80vh')};
transition: 0.25s, width 0s, height 0s;
overflow: scroll;
background-color: ${props=> props.styleData.productView ? props.styleData.productView.backgroundColor : "white"};
position: relative;
${props=>{
	if (props.styleData.productView && props.styleData.productView.borderRadius) {
		return `border-radius: ${props.styleData.productView.borderRadius} ${props.styleData.productView.borderRadius} 0 0`;
	}
}};

@media (min-width: 1200px) {
	max-height: 600px;
	max-width: 600px;
	margin-bottom: 0;
	opacity: ${({ transitionState }) => (transitionState === "entered" ? '1' : '0')};
	transform: ${({ transitionState }) => (transitionState === "entered" ? 'scale(1)' : 'scale(0.95)')};
	${props=>{
		if (props.styleData.productView && props.styleData.productView.borderRadius) {
			return `border-radius: ${props.styleData.productView.borderRadius}`;
		}
	}};
}
`

const PullHandleOversized = styled.div`
color: black;
padding: 0;
margin: 0;
background-color: ${props=> props.styleData.productView ? props.styleData.productView.backgroundColor : "white"};
width: 100%;
height: 50px;
z-index: 10;

${props=>{
	if (props.styleData.productView && props.styleData.productView.borderRadius) {
		return `border-radius: ${props.styleData.productView.borderRadius} ${props.styleData.productView.borderRadius} 0 0`;
	}
}};

&::after {
	pointer-events: none;
	content: '';
	width: 80px;
	height: 4px;
	border-radius: 100px;
	background-color: rgba(0,0,0,0.1);
	position: absolute;
	left: calc(50% - 40px);
	top: 5px;
}`

const PullHandleContainer = styled.div`
left: 0;
position: fixed;
width: 100%;
height: 12.5px;
overflow: hidden;
border-bottom: 1px solid rgba(0,0,0,0.025);
`

function PullHandle(props) {
	return (
		<PullHandleContainer>
		<PullHandleOversized {...props}/>
		</PullHandleContainer>
	)
}

const Content = styled.div`
min-height: calc(100% + 1px);
padding: 0px 15px 25px 15px;
background-color: ${props=> props.styleData.productView ? props.styleData.productView.backgroundColor : "white"};
`

const ProductList = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
flex-wrap: wrap;
background-color: ${props=> props.styleData.productView ? props.styleData.productView.backgroundColor : "white"};
`

const ProductItemContainer = styled.div`
z-index: 0;
cursor: pointer;
width: calc(50% - 20px);
padding: 0 10px;
padding-bottom: 20px;
letter-spacing: 1.0px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

img {
	width: calc(100% - 20px);
	max-height: 40vh;
	padding: 10px;
	object-fit: contain;
	background-color: transparent;
}
h3 {
  padding: 0;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  line-height: 20px;
  font-size: ${props=> props.styleData.carousel.headerText ? (props.styleData.carousel.headerText.size + "px"): "14px"};
  color: ${props=> props.styleData.carousel.headerText ? props.styleData.carousel.headerText.color : "#000000"};
  text-align: ${props=> props.align || 'center'};
  font-family: ${props=> props.styleData.headerText.font} !important;
}
h4 {
  width: 100%;
  text-align: ${props=> props.align || 'center'};
  font-size: ${props=> props.styleData.carousel.subheaderText ? (props.styleData.carousel.subheaderText.size + "px"): "14px"};
  color: ${props=> props.styleData.carousel.subheaderText ?  props.styleData.carousel.subheaderText.color : "#8e8e8e"};
  padding: 0;
  margin: 0;
  margin-top: 10px;
  font-family: ${props=> props.styleData.bodyText.font} !important;
}
`

function ProductItem(props) {
	return (
		<ProductItemContainer {...props}>
		<img src={props.product.imageUrl}/>
		<h3>{props.product.title}</h3>
		<h4>${props.product.price}</h4>
		</ProductItemContainer>
	)
}

export default function ProductsListModal(props) {
	const [products, setProducts] = useState([])
	const scrollRef = useRef(null)
	const context = useAppContext();

	const productItems = () => {
		return products.map(product=> {
        	return <ProductItem 
        	styleData={context.state.styleData}
        	onClick={()=>{
        		props.onSelect(product)
        	}}
        	product={product}/>				
		})
	}

	useEffect(()=>{
		if (!props.visible) return
		setProducts(props.products)
	}, [props.products])

	useEffect(()=>{
		document.body.style.overflow = props.visible ? 'hidden':'auto'

		return ()=>{
			document.body.style.overflow = 'auto'
		}
	}, [props.visible]);

	return (
		<CSSTransition
            in={props.visible}
            timeout={250}
            unmountOnExit 
            mountOnEnter>
            {(transitionState) => {
            	console.log(transitionState)
            	return(
            	<Background 
            	transitionState={transitionState}
            	onClick={e=>{
            		if (e.target === e.currentTarget ) {
            			props.onClose()
            		}
            	}}>
	        		<Container 
	        		ref={scrollRef}
	        		styleData={context.state.styleData}
        			onTouchEnd={(e)=>{
        				debugger
						if(scrollRef.current.scrollTop < -50) {
							scrollRef.current.style.marginTop = -e.target.scrollTop + "px"
							props.onClose()
						}
					}}
	        		transitionState={transitionState}>
	        			<Content styleData={context.state.styleData}>
	        				<PullHandle styleData={context.state.styleData}/>
		        			<Label large styleData={context.state.styleData}>Visible Products</Label>
		        			<ProductList 
		        			styleData={context.state.styleData}
		        			transitionState={transitionState}>
		        			{ productItems() }
		        			</ProductList>	
			        	</Content>
	            	</Container>
	            </Background>
	            )
            }}
		</CSSTransition>
	)
}