import { h, Component } from 'preact';
import * as React from 'preact';
import * as ReactDOM from 'preact';
import { useState, useEffect } from 'preact/hooks'
import CartManager from '../../helpers/cart-manager.js'
import style from './style.scss';
import * as Analytics from '../../helpers/analytics'
import PulseLoader from "react-spinners/PulseLoader";
import { FreckleAppContext, useAppContext } from '../../context/state'
import Button from '../../components/Button'
import styled from 'styled-components'

const CartSuperContainer = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display: flex;
flex-direction: row;
z-index: 100;

font-family: ${props=> props.styleData.bodyText.font} !important;

h1,h2,h3,h4 {
	font-family: ${props=> props.styleData.headerText.font} !important;
}

.cart-background {
	flex:  1;
	height: 100%;
	width: 100%;
	background-color: transparent;
}`


export default function Cart(props) {
	const context = useAppContext();

	const [,forceUpdate] = useState(null);
	const [preparingToCheckout, setPreparingToCheckout] = useState(false);

	const { styleData, videoData } = context.state;

	const reloadCart = () => {
		forceUpdate(Math.random())
	}

	useEffect(()=>{
		Analytics.logEvent("view_cart")

		document.addEventListener('freckleCartUpdated', reloadCart);
		document.addEventListener('freckleCartLoaded', reloadCart);
	}, [])

	const incrementQuantity = (item) => {
		CartManager.add({id: item.id}, item.variant)
	}

	const decrementQuantity = (item) => {
		CartManager.remove({id: item.id}, item.variant)
	}

	const checkout = async () => {
		const domain = props.data.shopifyStore || props.data.storeUrl

		Analytics.logEvent("checkout", null, CartManager.allItemIds())
		Analytics.sendfb_InitiateCheckout()
		const products = CartManager.allItems()

		if(videoData.storeType == "shopify") {
			setPreparingToCheckout(true)
			const cartString = Object.keys(products).map(key => `${products[key].variant.id}:${products[key].quantity}`).join(",")
			const url = `https://${domain}/cart/${cartString}?utm_source=freckle`
			setPreparingToCheckout(true)
			window.open(url)

		} else {
			setPreparingToCheckout(true)
			const wooCartString = Object.keys(products).map(key =>{ 
				return `${products[key].variant.id}:${products[key].quantity}` 
			}).join(",")

			const checkoutUrl = `https://${domain}/cart/?quick_cart=${wooCartString}`

			setPreparingToCheckout(false)

	        window.open(checkoutUrl)

		}
	}


	let products = CartManager.allItems()

	const checkoutEnabled = Object.keys(products).length > 0

	let cartSubtotal = 0
	if(Object.keys(products).length > 0) cartSubtotal = Object.keys(products).map(key => {
		return products[key].quantity * products[key].variant.price
	}).reduce((a,b)=> parseInt(a)+parseInt(b)).toFixed(2) / 1

	return(
	<CartSuperContainer
	styleData={context.state.styleData}>
		<div class="cart-background" onClick={props.onClose}/>
		<div class="cart-container">
			<div className="cart-header">
				<h4>CART</h4>
				<img onClick={props.onClose} src={`data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCIgdmlld0JveD0iMCAwIDY0IDY0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFydGJvYXJkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjI0LjAwMDAwMCwgLTE5MS4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIyNC4wMDAwMDAsIDE5MS4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIGZpbGw9IiNGRkZGRkYiIGN4PSIzMiIgY3k9IjMyIiByPSIzMiI+PC9jaXJjbGU+CiAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMxLjUwMDAwMCwgMzIuNTAwMDAwKSByb3RhdGUoNDUuMDAwMDAwKSB0cmFuc2xhdGUoLTMxLjUwMDAwMCwgLTMyLjUwMDAwMCkgdHJhbnNsYXRlKDE4LjAwMDAwMCwgMTkuMDAwMDAwKSIgZmlsbD0iIzAwMDAwMCI+CiAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMuNTAwMDAwLCAxMy41MDAwMDApIHJvdGF0ZSg5MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTMuNTAwMDAwLCAtMTMuNTAwMDAwKSAiIHg9IjExIiB5PSIwIiB3aWR0aD0iNSIgaGVpZ2h0PSIyNyI+PC9yZWN0PgogICAgICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUtQ29weSIgeD0iMTEiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjI3Ij48L3JlY3Q+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=`}/>
			</div>
			<div className="items">
			{ 
				Object.keys(products).map(productKey => {
					const item = products[productKey]
					const variantTitle = item.variant.title ? item.variant.title : ""
					return(
					<div className="cart-item">
						<img src={ item.images[0] }/>
						<div className="item-right">
							<div className="item-text">
								<div className="cart-item-title">{ item.title }</div>
								<div className="cart-item-price">{ props.currencySymbol }{ (item.variant.price).toFixed(2) }</div>
							</div>
							<div className="item-text">
								<div className="cart-item-variant">{ variantTitle }</div>
							</div>
							<div className="quantity-container">
								<div className="control" onClick={()=>decrementQuantity(item)}>-</div><div className="quantity">{ item.quantity }</div><div className="control" onClick={()=>incrementQuantity(item)}>+</div>
							</div>
						</div>
					</div>
					)
				})
			}
			</div>
			<div class="cart-footer">
				<div class="subtotal-line">
					<h4 className="label">Subtotal</h4>
					<h4 className="cart-price">{ props.currencySymbol }{ cartSubtotal.toFixed(2) }</h4>
				</div>
			<Button
			styleData={context.state.styleData}
			disabled={!checkoutEnabled}
			onClick={checkout}>
			{ preparingToCheckout ? <PulseLoader color="#fff" size={5}/> : "CHECKOUT" }
			</Button>
			</div>
		</div>
	</CartSuperContainer>
	)

}

