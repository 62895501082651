import * as React from 'preact'
import {render} from 'preact'
import styled from 'styled-components'

import MicrositeWrapper from './routes/microsite'
import Router from 'preact-router';

import Embed from './routes/embed'

render((
<Router>
  <MicrositeWrapper path="/:siteid"/>
  <Embed path="/embed/:siteid"/>
  <MicrositeWrapper path="/:siteid/:videoid"/>
  <MicrositeWrapper path="/preview" bootstrap/>
 </Router>
), document.getElementById('app'))