
export function coloredHotspotIcon(iconName, iconColor, accentColor="#000000") {
  const svgString = HotspotType[iconName]
  return svgString.replaceAll("REPLACE_WITH_COLOR", iconColor).replaceAll("REPLACE_WITH_ACCENT_COLOR", accentColor)
}

export const HotspotType = {
	standard: `
<svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-188.000000, -98.000000)" fill="REPLACE_WITH_COLOR" fill-rule="nonzero">
            <path d="M195.5,114.883594 C191.27683,114.388666 188,110.797904 188,106.441797 C188,102.08569 191.27683,98.4949279 195.5,98 L195.5,103.086736 C194.054256,103.517011 193,104.856287 193,106.441797 C193,108.027307 194.054256,109.366583 195.5,109.796858 L195.5,114.883594 Z M197.5,98 C201.72317,98.4949279 205,102.08569 205,106.441797 C205,110.797904 201.72317,114.388666 197.5,114.883594 L197.5,109.796858 C198.945744,109.366583 200,108.027307 200,106.441797 C200,104.856287 198.945744,103.517011 197.5,103.086736 L197.5,98 Z" id="Combined-Shape"></path>
        </g>
    </g>
</svg>`,
	bag: `
<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-208.000000, -126.000000)">
            <g id="Group-10" transform="translate(208.000000, 126.000000)">
                <circle id="Oval" fill="REPLACE_WITH_COLOR" cx="11" cy="11" r="11"></circle>
                <g id="Group-Copy-2" transform="translate(5.000000, 5.000000)" stroke="REPLACE_WITH_ACCENT_COLOR">
                    <path d="M1.83898425,2.5625 L0.877347826,8.65035973 C0.864304504,8.73293347 0.857752754,8.81640245 0.857752754,8.9 C0.857752754,9.7836556 1.57409715,10.5 2.45775275,10.5 L9.54224725,10.5 C9.6258448,10.5 9.70931378,10.4934482 9.79188751,10.4804049 C10.6647211,10.3425324 11.2605247,9.52319327 11.1226522,8.65035973 L10.1610158,2.5625 L1.83898425,2.5625 Z" id="Rectangle"></path>
                    <path d="M4.23529412,3.4375 L4.23529412,2.0625 C4.23529412,0.6875 4.88235294,0 6.17647059,0 C7.47058824,0 8.11764706,0.6875 8.11764706,2.0625 L8.11764706,3.4375" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
bag4: `
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill-rule="evenodd" xmlns:v="https://vecta.io/nano"><circle cx="11" cy="11" r="11" fill="REPLACE_WITH_COLOR"/><g fill="REPLACE_WITH_ACCENT_COLOR"><g fill-rule="nonzero"><path d="M7.645 7.892l-.712 7.135h8.472l-.712-7.135H7.645zm7.926-.973L16.477 16H5.861l.906-9.081h8.805z"/><path d="M9.956 9.189h-.971V6.108C8.985 4.747 9.805 4 11.25 4s2.265.747 2.265 2.108v3.081h-.971V6.108c0-.802-.366-1.135-1.294-1.135s-1.294.334-1.294 1.135v3.081z"/></g><circle cx="13.029" cy="9.514" r="1"/><circle transform="matrix(.999848 -.017452 .017452 .999848 5.654343 4.065773)" cx="3.721" cy="5.514" r="1"/></g></svg>
`,
    plus: `<svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" fill="REPLACE_WITH_ACCENT_COLOR" r="7"/><path d="m10 20c-5.5228475 0-10-4.4771525-10-10s4.4771525-10 10-10 10 4.4771525 10 10-4.4771525 10-10 10zm1.3888889-11.38888889v-3.61111111h-2.77777779v3.61111111h-3.61111111v2.77777779h3.61111111v3.6111111h2.77777779v-3.6111111h3.6111111v-2.77777779z" fill="REPLACE_WITH_COLOR"/></g></svg>`,
	roundedProductImage: "roundedProductImage",
	circleProductImage: "circleProductImage",
	tearRight: "tearRight",
	tearLeft: "tearLeft",
	tearTop: "tearTop",
	tearBottom: "tearBottom"
}